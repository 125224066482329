import React from 'react';

import IncaTopsLogoTerms from '~/Resources/images/IncaTopsLogoTerms.png';
import './styles.scss';

const TermsEn = () => {
  return (
    <div className="terms">
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '30px' }}>PRIVACY POLICY</p>
        <p style={{ fontSize: '25px' }}>
          <b>INCA TOPS S.A.</b>
        </p>
      </div>
      <p style={{ float: 'right' }}>Arequipa, October 2020</p>
      <p style={{ marginTop: '5rem' }}>
        <b>1. SCOPE</b>
      </p>
      <p className="item-content">
        This “Privacy Policy on the Protection of Personal Data” will apply to
        all the databases and/or archives that contain personal data that would
        be the object of processing by INCA TOPS S.A. (hereinafter known as INCA
        TOPS) which has the following information:
      </p>
      <p className="sub-item-content">
        <b> Name:</b> INCA TOPS S.A.
      </p>
      <p className="sub-item-content">
        <b>RUC N°:</b> 20100199743
      </p>
      <p className="sub-item-content">
        <b>Address:</b> Avenida Miguel Forga 348 Z.I. Parque Industrial,
        Arequipa
      </p>

      <p>
        <b>2. INTRODUCTION</b>
      </p>
      <p className="item-content">
        At INCA TOPS we ensure the strictest confidentiality and protection of
        personal data belonging to our clients, coworkers, providers, etc.
      </p>
      <p className="item-content">
        This document describes the “Privacy Policy on the Protection of
        Personal Data” that regulates the processing of personal data that we at
        INCA TOPS carry out in the development of our commercial activities.
      </p>
      <p>
        <b>3. OBJECT AND PURPOSE</b>
      </p>
      <p className="item-content">
        At INCA TOPS were are conscious of the elevated consideration that the
        privacy of our clients, coworkers, and all those persons that are
        interested in our products and services has. Since this consideration is
        consequential, we are committed to respecting the privacy, and
        protecting the confidentiality of private information and personal data
        of all parties involved. Thus, the object of this general privacy policy
        is to introduce the manner in which the personal data we access in our
        commercial activities, is collected, processed, and protected.
      </p>
      <p className="item-content">
        Private personal information does not include data that is available
        through sources accessible to the public. It is understood that such
        sources include, but are not limited to electronic communication
        mediums, optics and other technology designed to provide information to
        the public and open to general consultation, phone books, newspapers and
        magazines, social media, professional lists, anonymous jurisprudence
        repertoires, Public Records administered by the Superintendencia
        Nacional de Registros Públicos, and any other record or database
        classified as public according to the law.
      </p>
      <p>
        <b>4. PROCESSING OF PERSONAL DATA IN THE HIRING OF PERSONNEL</b>
      </p>
      <p className="item-content">
        <b>A. PURPOSE OF PROCESSING DATA IN THE HIRING OF PERSONNEL</b>
      </p>
      <p className="sub-item-content">
        INCA TOPS will collect personal data from those interested in a job
        position with the purpose of:
      </p>
      <p className="sub-item-content">
        i. Managing the selection process for the hiring of personnel to work
        inside the company.
      </p>
      <p className="sub-item-content">
        ii. Storing candidate information for the hiring of personnel to work
        inside INCA TOPS according to the needs of the company.
      </p>
      <p className="sub-item-content">
        iii. Registering the personal data in the database “Job Applicants”
        owned by INCA TOPS.
      </p>
      <p className="item-content">
        <b>B. MANDATORY PERSONAL DATA</b>
      </p>
      <p className="sub-item-content">
        To carry out the purposes described in this privacy policy, it is
        mandatory that applicants provide us the following personal data: full
        name, DNI number, immigration card number, address, photo, mobile and
        land line phone numbers, e-mail, birthdate and birthplace, gender,
        profession, age, academic information, and contact person information.
      </p>
      <p className="item-content">
        <b>C. CONSEQUENCES OF PROVIDING DATA OR REFUSAL TO DO SO</b>
      </p>
      <p className="sub-item-content">
        If a job applicant does not provide the mandatory data, they will not be
        able to participate in the hiring process of personnel.
      </p>
      <p className="item-content">
        <b>D. RETENTION PERIOD</b>
      </p>
      <p className="sub-item-content">
        The job applicants’ personal data will be processed temporarily during
        the development of the hiring process and will be stored in the database
        “Job Applicants” for the maximum of one (1) year.
      </p>
      <p>
        <b>5. PROCESSING OF PERSONAL DATA IN PUBLICITY</b>
      </p>
      <p className="item-content">
        <b>A. PURPOSE OF PROCESSING DATA IN PUBLICITY</b>
      </p>
      <p className="sub-item-content">
        INCA TOPS will collect personal data from those persons interested in
        receiving publicity with the purpose of:
      </p>
      <p className="sub-item-content">
        <b>
          1. (i) Sending publicity from INCA TOPS and/or the companies that form
          part of the same group, and/or from its commercial associates, public
          or private, for which they may use, among other means, call centers,
          telephone systems, text messages, mass and/or personalized emails,
          magazines and/or catalogs, and/or any other similar medium of
          communication that currently exists, or will exist.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          2. Registering the personal data in the database “Publicity” which is
          owned by INCA TOPS.
        </b>
      </p>
      <p className="item-content">
        <b>B. MANDATORY AND OPTIONAL PERSONAL DATA</b>
      </p>
      <p className="sub-item-content">
        To carry out the purposes described in this privacy policy, it is
        mandatory that we are provided the following personal data: full name,
        email, company, country of residence.
      </p>
      <p className="sub-item-content">
        The following are optional personal data: address, landline and mobile
        phone numbers.
      </p>
      <p className="item-content">
        <b>C. CONSEQUENCES OF PROVIDING DATA OR REFUSAL TO DO SO</b>
      </p>
      <p className="sub-item-content">
        Publicity will only be sent through the mediums provided. However, the
        full name, email, company, and country of residence are strictly
        necessary for the processing of personal data for these purposes.
      </p>
      <p className="item-content">
        <b>D. RETENTION PERIOD</b>
      </p>
      <p className="sub-item-content">
        The personal data will be processed while they are necessary to fulfill
        the purposes previously described, or until the owner expresses their
        intention to stop the processing of their data through the channels made
        available to them to exercise their ARCO rights.
      </p>
      <p>
        <b>
          6. PROCESSING OF PERSONAL DATA FOR PURPOSES OF REQUEST AND BUSINESS
          RELATIONS
        </b>
      </p>
      <p className="item-content">
        <b>PURPOSE OF PROCESSING DATA FOR REQUEST AND BUSINESS RELATIONS</b>
      </p>
      <p className="item-content">
        INCA TOPS will collect the personal data of clients with the purpose of:
      </p>
      <p className="item-content">
        i. Making a REQUEST for a product or service of INCA TOPS
      </p>
      <p className="item-content">
        ii. Executing the contractual relationship that arises between a client
        and INCA TOPS
      </p>
      <p className="item-content">
        iii. (iii) Using, providing and/or transferring this information to the
        technology service providers that are contracted by INCA TOPS, as well
        as authorities and third parties authorized by law.
      </p>
      <p className="item-content">
        iv. Fulfilling the statistical and historical purposes of INCA TOPS.
      </p>
      <p className="item-content">
        v. Registering the personal data in the database “Clients” owned by INCA
        TOPS
      </p>
      <p className="item-content">
        vi. Evaluating any request made and/or that could be made, as well as
        conducting satisfaction surveys.
      </p>
      <p className="item-content">
        vii. Informative purposes, whether they be for INCA TOPS and/or for the
        companies that form part of the same group and/or for its commercial
        associates, public or private, for which they may use, among other
        mediums, call centers, telephone systems, text messages, mass and/or
        personalized emails, magazines and/or catalogs, and/or any other similar
        medium of communication that currently exists, or will exist.
      </p>
      <p className="item-content">
        viii. Using, providing, and/or transferring this information to comply
        with the obligations and/or requirements that are generated by virtue of
        the current regulations in the Peruvian legal system, including those
        regulations from the anti- money laundering and counter terrorism
        financing system, and prudential regulations.
      </p>
      <p className="item-content">
        <b>B. MANDATORY AND OPTIONAL PERSONAL DATA</b>
      </p>
      <p className="sub-item-content">
        To carry out the purposes described in this privacy policy, it is
        mandatory that we are provided the following personal data: full name,
        phone number, email, signature, tax identification number, and bank
        account number.
      </p>
      <p className="item-content">
        <b>C. CONSEQUENCES OF PROVIDING DATA OR REFUSAL TO DO SO</b>
      </p>
      <p className="sub-item-content">
        If we are not provided the mandatory data, we will not be able to
        provide the requested quote, nor will the client be able to acquire our
        products or services.
      </p>
      <p className="item-content">
        <b>D. RETENTION PERIOD</b>
      </p>
      <p className="sub-item-content">
        The personal data will be processed while they are necessary to fulfill
        the purposes previously described, or up to a maximum period of five
        (05) years from when our product or service was acquired, except for
        legal requirements which may exceed said period.
      </p>
      <p>
        <b>
          7. PROCESSING OF PERSONAL DATA FOR PURPOSES OF REQUEST AND BUSINESS
          RELATIONS WITH CLIENTS THAT
        </b>
      </p>
      <p className="item-content">
        <b>RESIDE IN THE EUROPEAN UNION</b>
      </p>
      <p className="item-content">
        <b>
          A. PURPOSE OF PROCESSING DATA FOR REQUEST AND BUSINESS RELATIONS
        </b>
      </p>
      <p className="sub-item-content">
        INCA TOPS will collect the personal data of clients with the purpose of:
      </p>
      <p className="sub-item-content">
        <b>i. Making a quote for a product or service of INCA TOPS</b>
      </p>
      <p className="sub-item-content">
        <b>
          ii. Executing the contractual relationship that arises between the
          client and INCA TOPS
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          iii. Using, providing and/or transferring this information to the
          technology service providers that are contracted by INCA TOPS, as well
          as authorities and third parties authorized by law.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          iv. Fulfilling the statistical and historical purposes of INCA TOPS.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          v. Registering the personal data in the database “Clients” owned by
          INCA TOPS
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          vi. Evaluating any request made and/or that could be made, as well as
          conducting satisfaction surveys.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          vii. Informative purposes, whether they be for INCA TOPS and/or for
          the companies that form part of the same group and/or for its
          commercial associates, public or private, for which they may use,
          among other mediums, call centers, telephone systems, text messages,
          mass and/or personalized emails, magazines and/or catalogs, and/or any
          other similar medium of communication that currently exists, or will
          exist.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          viii. Using, providing, and/or transferring this information to comply
          with the obligations and/or requirements that are generated by virtue
          of the current regulations in the Peruvian legal system, including
          those regulations from the anti- money laundering and counter
          terrorism financing system, and prudential regulations.
        </b>
      </p>
      <p className="item-content">
        <b>B. BASIS OR LEGAL FOUNDATION OF PROCESSING DATA</b>
      </p>
      <p className="sub-item-content">
        The processing of clients’ personal data is supported in art. 6.1b,
        which establishes that all processing of data is lawful when “the
        processing is necessary for the performance of a contract to which the
        data subject is party or in order to take steps at the request of the
        data subject prior to entering into a contract.”
      </p>
      <p className="sub-item-content">
        Likewise, we remind you that you have the right to contact the control
        authority of the European Union regarding the protection of personal
        data in order to enforce the rights that the regulatory framework
        provides you.
      </p>
      <p className="sub-item-content">
        On the other hand, we inform you of automated decisions regarding
        profiling that occurs with the information that is provided to us,
        decisions that are framed within the stated purposes.
      </p>
      <p className="item-content">
        <b>C. MANDATORY AND OPTIONAL PERSONAL DATA</b>
      </p>
      <p className="sub-item-content">
        To carry out the purposes described in this privacy policy, it is
        mandatory that we are provided the following personal data: full name,
        phone number, email, signature, tax identification number, and bank
        account number.
      </p>
      <p className="item-content">
        <b>D. CONSEQUENCES OF PROVIDING DATA OR REFUSAL TO DO SO</b>
      </p>
      <p className="sub-item-content">
        If we are not provided the mandatory data, we will not be able to
        provide the requested quote, nor will the client be able to acquire our
        products or services.
      </p>
      <p className="item-content">
        <b>E. RETENTION PERIOD</b>
      </p>
      <p className="sub-item-content">
        The personal data will be processed while they are necessary to fulfill
        the purposes previously described, or up to a maximum period of five
        (05) years from when our product or service was acquired, except for
        legal requirements which may exceed said period.
      </p>
      <p>
        <b>
          8. PROCESSING OF PERSONAL DATA FOR THE ATTENTION TO COMPLAINTS AND
          CLAIMS
        </b>
      </p>
      <p className="item-content">
        <b>
          A. PURPOSE OF PROCESSING DATA FOR THE ATTENTION TO COMPLAINTS AND
          CLAIMS
        </b>
      </p>
      <p className="sub-item-content">
        INCA TOPS will collect the personal data of persons that present a
        claim, complaint, or suggestion with the purpose of:
      </p>
      <p className="sub-item-content">
        Responding to the complaint or claim that may be presented, as well as
        taking note of the suggestions that are made.
      </p>
      <p className="sub-item-content">
        Recording the personal data to the database “Complaints and Claims,”
        which is owned by INCA TOPS.
      </p>
      <p className="sub-item-content">
        Complying to the established Código de Protección al Consumidor, the
        Reglamento del Libro de Reclamaciones del Código de Protección al
        Consumidor, and other applicable regulations.
      </p>
      <p className="item-content">
        <b>B. MANDATORY PERSONAL DATA</b>
      </p>
      <p className="sub-item-content">
        To carry out the purposes described in the privacy policy, it is
        mandatory that we are provided the following personal data: full name,
        email, address, DNI number, cell phone number, age.
      </p>
      <p className="item-content">
        <b>C. CONSEQUENCES OF PROVIDING DATA OR REFUSAL TO DO SO</b>
      </p>
      <p className="sub-item-content">
        In the event that the consumer does not provide at least their name, DNI
        number, address or email, date of the claim or complaint and their
        details, these complaints and/or claims will be considered as not
        presented.
      </p>
      <p className="item-content">
        <b>D. RETENTION PERIOD</b>
      </p>
      <p className="sub-item-content">
        The personal data will be processed while they are necessary to fulfill
        the purposes previously described, and for the maximum period of two
        (02) years from the date that the complaint or claim was presented.
      </p>
      <p>
        <b>9. CONFIDENTIALITY OF PERSONAL DATA</b>
      </p>
      <p className="item-content">
        The personal data provided will be treated with complete
        confidentiality. INCA TOPS is committed to keeping professional secrecy
        indefinitely regarding the personal data, and guarantees the duty to
        protecting them by adopting all of the necessary security measures.
      </p>
      <p>
        <b>10. TRANSFER, COMISSION, AND CROSS-BORDER FLOW OF DATA</b>
      </p>
      <p className="item-content">
        INCA TOPS will be able to transfer the personal data within the national
        territory or to the exterior, through any medium, to its subsidiaries,
        linked legal persons, to its service providers, whether they be a
        Peruvian national or legal person, solely for the fulfillment of the
        ends described in relation to each one of the processing terms. The
        relationship of such people can be consulted at the webpage:
      </p>
      <p className="item-content">
        <a href="https://incatops.com/en/supplieragreements">
          www.incatops.com/en/supplieragreements
        </a>
      </p>
      <p>
        <b>11. EXERCISE OF RIGHTS</b>
      </p>
      <p className="item-content">
        The users who have provided their personal data to INCA TOPS can refer
        to this policy, with the purpose of exercising their rights of
        information, access, updating, inclusion, rectification, deletion,
        preventing the furnishing of their personal data, the opposing of
        processing or the processing objective of the data, under the terms set
        forth by the current Peruvian legislature.
      </p>
      <p className="item-content">
        In order to exercise these rights, users can address, through any medium
        that is able to prove the sending and receipt of the request, to the
        following: Avenida Miguel Forga 348 Z.I. Parque Industrial, Arequipa, or
        by writing to the email derechosarco@incatops.com with the subject
        “Personal Data Protection,” specifying the data, proving their identity,
        and the reasons for the request.
      </p>
      <p className="item-content">
        Additionally, the owners of the personal data may present a claim to the
        Autoridad Nacional de Protección de Datos Personales, addressing the
        Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle
        Scipión Llona N° 350, Miraflores, Lima, Perú, to consider whether they
        were not attended to in the exercise of their rights.
      </p>
      <p>
        <b>12. CHANGES IN THE PRIVACY POLICY</b>
      </p>
      <p className="item-content">
        INCA TOPS reserves the right to make at any moment changes, adjustments,
        or updates to this privacy policy, to meet new legal provisions,
        regulations, jurisprudence or adopted internal policies.
      </p>
    </div>
  );
};

TermsEn.propTypes = {};
export default TermsEn;
