/*
    Breakepoints
    xs, sm, md, lg, xl, xxl
*/

// eslint-disable-next-line import/prefer-default-export
export const UnderBreakpoint = (brekpoints, option) => {
  let result = false;
  const brekpointsKeys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const sliceIndex = brekpointsKeys.indexOf(option);
  const under = brekpointsKeys.slice(0, sliceIndex);
  const upper = brekpointsKeys.slice(sliceIndex + 1, 6);
  under.forEach(key => {
    result = result || brekpoints[key];
  });
  upper.forEach(key => {
    result = result && !brekpoints[key];
  });
  return result;
};

export const HandleErroMessage = message => {
  console.error(message);
};

/*
  Filters of products
*/

export const AddFilter = (
  subCategoryFilterName,
  ordering,
  minimumOrderLte,
  minimumOrderGte,
  atributesFilterName,
  weightFilterName,
  suggestedNeedlesFilterName,
  metricNumbersFilterName,
  stockFilterName,
  tonalitiesFilterName,
  productsFilterName,
) => {
  const filters = [];
  if (ordering !== '') {
    switch (ordering) {
      case 'name':
        filters.push('Alphabetical asc');
        break;
      case '-name':
        filters.push('Alphabetical desc');
        break;
      case 'minimum_order':
        filters.push('Minimum order asc');
        break;
      case '-minimum_order':
        filters.push('Minimum order desc');
        break;
      default:
        break;
    }
  }

  if (minimumOrderLte !== null && minimumOrderLte !== '') {
    filters.push(`${minimumOrderLte}`);
  }
  if (minimumOrderGte !== null && minimumOrderGte !== '') {
    filters.push(`${minimumOrderGte}`);
  }
  if (atributesFilterName.length > 0) {
    filters.push(`${atributesFilterName.toString()}`);
  }
  if (weightFilterName) {
    filters.push(`${weightFilterName.toString()}`);
  }
  if (suggestedNeedlesFilterName) {
    filters.push(`${suggestedNeedlesFilterName.toString()}`);
  }
  if (subCategoryFilterName !== '') {
    filters.push(`${subCategoryFilterName.toString()}`);
  }
  if (metricNumbersFilterName !== '') {
    filters.push(`${metricNumbersFilterName.toString()}`);
  }
  if (stockFilterName !== '') {
    filters.push(`${stockFilterName.toString()}`);
  }
  if (tonalitiesFilterName !== '') {
    filters.push(`${tonalitiesFilterName.toString()}`);
  }
  if (productsFilterName !== ''){
    filters.push(`${productsFilterName.toString()}`);
  }
  
  return filters;
};

export const getAllProductsFilters = (allProducts, filters) => {
  let prevProducts = allProducts;
  const { ordering, minimumOrderLte, minimumOrderGte } = filters;
  if (ordering !== '') {
    switch (ordering) {
      case 'name':
        prevProducts.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        break;
      case '-name':
        prevProducts.sort(function(a, b) {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        });
        break;
      case 'minimum_order':
        prevProducts.sort(function(a, b) {
          return a.minimum_order - b.minimum_order;
        });
        break;
      case '-minimum_order':
        prevProducts.sort(function(a, b) {
          return b.minimum_order - a.minimum_order;
        });
        break;
      default:
        break;
    }
  }
  if (
    minimumOrderLte !== null &&
    minimumOrderLte !== undefined &&
    minimumOrderLte !== '' &&
    minimumOrderLte !== 0
  ) {
    prevProducts = prevProducts.filter(
      product => product.minimum_order <= minimumOrderLte
    );
  }
  if (
    minimumOrderGte !== null &&
    minimumOrderGte !== undefined &&
    minimumOrderGte !== '' &&
    minimumOrderGte !== 0
  ) {
    prevProducts = prevProducts.filter(
      product => product.minimum_order >= minimumOrderGte
    );
  }
  return prevProducts;
};
