import React, { createContext, useReducer } from 'react';

import PropTypes from 'prop-types';

const initialState = {
  cartItems: JSON.parse(localStorage.getItem('InkaTopsCart')) || [],
};

export const CART_ACTIONS = {
  ADD_ITEM: 0,
  UPATE_ITEM: 0, // Distinto key para legibilidad, misma función para agregar y actualizar
  DELETE_ITEM: 1,
  CLEAR: 2,
};

const CartReducer = (state, action) => {
  switch (action.type) {
    case CART_ACTIONS.ADD_ITEM: {
      const newCart = [...state.cartItems];
      const itemIndex = newCart.findIndex(
        i =>
          i.colorCode === action.payload.colorCode &&
          i.name === action.payload.name
      );
      if (itemIndex !== -1) {
        newCart[itemIndex] = action.payload;
      } else {
        newCart.push(action.payload);
      }
      localStorage.setItem('InkaTopsCart', JSON.stringify(newCart));
      return {
        cartItems: newCart,
      };
    }
    case CART_ACTIONS.DELETE_ITEM: {
      const newCart = [...state.cartItems];
      const itemIndex = newCart.findIndex(
        i =>
          i.colorCode === action.payload.colorCode &&
          i.name === action.payload.name
      );
      if (itemIndex !== -1) {
        newCart.splice(itemIndex, 1);
      }
      localStorage.setItem('InkaTopsCart', JSON.stringify(newCart));
      return {
        cartItems: newCart,
      };
    }
    case CART_ACTIONS.CLEAR: {
      localStorage.removeItem('InkaTopsCart');
      return {
        cartItems: [],
      };
    }
    default: {
      localStorage.setItem('InkaTopsCart', JSON.stringify([]));
      return { cartItems: [] };
    }
  }
};

const CartContext = createContext({});

function CartProvider({ children }) {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  return (
    <CartContext.Provider value={[state, dispatch]}>
      {children}
    </CartContext.Provider>
  );
}

CartProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { CartProvider, CartContext };
