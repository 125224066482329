import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  userToken: JSON.parse(localStorage.getItem('user')) || '',
  email: JSON.parse(localStorage.getItem('userEmail')) || '',
  canDownloadExcel: JSON.parse(localStorage.getItem('canDownloadExcel')) || '',
};

export const USER_ACTIONS = {
  ADD_TOKEN: 0,
  REMOVE_TOKEN: 1,
  ADD_EMAIL: 2,
  REMOVE_EMAIL: 3,
  ADD_EXCEL_DOWNLOAD: 4,
  REMOVE_EXCEL_DOWNLOAD: 5,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.ADD_TOKEN: {
      const newToken = action.payload;
      localStorage.setItem('user', JSON.stringify(newToken));
      return {
        ...state,
        userToken: action.payload,
      };
    }
    case USER_ACTIONS.REMOVE_TOKEN: {
      localStorage.removeItem('user');
      return {
        ...state,
        userToken: '',
      };
    }
    case USER_ACTIONS.ADD_EMAIL: {
      const emailUser = { email: action.payload };
      localStorage.setItem('userEmail', JSON.stringify(emailUser));
      return {
        ...state,
        email: action.payload,
      };
    }
    case USER_ACTIONS.REMOVE_EMAIL: {
      localStorage.removeItem('userEmail');
      return {
        ...state,
        email: '',
      };
    }
    case USER_ACTIONS.ADD_EXCEL_DOWNLOAD: {
      const canDownloadExcel = action.payload;
      localStorage.setItem('canDownloadExcel', JSON.stringify(canDownloadExcel));
      return {
        ...state,
        canDownloadExcel: action.payload,
      };
    }
    case USER_ACTIONS.REMOVE_EXCEL_DOWNLOAD: {
      localStorage.removeItem('canDownloadExcel');
      return {
        ...state,
        canDownloadExcel: '',
      };
    }

    default: {
      return state;
    }
  }
};

const UserContext = createContext({});

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { UserProvider, UserContext };
