// Import core modules
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button } from 'antd';
import { DeleteFilled, ShoppingOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

// Context
import { CartContext, CART_ACTIONS } from '~/Contexts/CartProvider';

// Import Components
import Border from '~/Components/Border';
import ColorCircle from '~/Components/ColorCircle';

// Inport Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Styles
import './index.scss';

const { Title, Text } = Typography;

function Cart(props) {
  const { showPopover } = props;
  const [state, dispatch] = useContext(CartContext);

  const screens = useBreakpoint();
  const { t } = useTranslation('common');

  const deleteItemInCart = product => {
    dispatch({
      type: CART_ACTIONS.DELETE_ITEM,
      payload: product,
    });
  };

  return (
    <div className="Cart">
      <Row justify="center">
        <Col className="title-container">
          <Title
            className="f500"
            level={UnderBreakpoint(screens, 'md') ? 4 : 3}
          >
            {t('CART')}
          </Title>
        </Col>
        <Col span={24}>
          <Border position="middle" />
        </Col>
        <Col span={24}>
          {state.cartItems.length ? (
            state.cartItems.map(product => (
              <React.Fragment key={`${product.id}-${product.colorID}`}>
                <Row
                  justify={
                    UnderBreakpoint(screens, 'sm')
                      ? 'space-between'
                      : 'space-around'
                  }
                  align="middle"
                  className="product-container"
                >
                  <Col
                    className="product-name"
                    span={UnderBreakpoint(screens, 'sm') ? 24 : 4}                    
                  >
                    <Title
                      className="f500"
                      level={UnderBreakpoint(screens, 'sm') ? 5 : 4}
                    >
                      {product.name}
                    </Title>
                  </Col>
                  <Col>
                    <img className="product-image" src={product.image} alt="" />
                  </Col>
                  <Col>
                    <ColorCircle
                      img={product.colorImage}
                      code={product.colorCode}
                    />
                  </Col>
                  <Col className="input-number-wrapper">
                    <Title
                      className="f500"
                      level={UnderBreakpoint(screens, 'sm') ? 5 : 4}
                    >
                      {`${product.quantity} kg`}
                    </Title>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      size="large"
                      className="trash-icon-button"
                      icon={<DeleteFilled className="trash-icon" />}
                      onClick={() => deleteItemInCart(product)}
                    />
                  </Col>
                </Row>

                <Border position="middle" />
              </React.Fragment>
            ))
          ) : (
            <div className="empty-container">
              <ShoppingOutlined className="cart-icon" />
              <Text className="empty-text f500" level={4}>
                {t('Your cart is currently empty')}
              </Text>
            </div>
          )}
        </Col>
      </Row>
      <Row
        className="product-container"
        gutter={4}
        justify="end"
        align="middle"
      >
        <Col>
          {state.cartItems.length ? (
            <Link to="/quotation" onClick={() => showPopover(false)}>
              <Button type="primary"> {t('Request')}</Button>
            </Link>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </div>
  );
}

Cart.propTypes = {
  showPopover: PropTypes.func.isRequired,
};

export default Cart;
