// Import core modules
import React, { useEffect, useState, Children } from 'react';
import PropTypes from 'prop-types';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueries } from 'react-query';
import Magnifier from 'react-magnifier';

import {
  InfoCircleOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import { Row, Col, Typography, Button } from 'antd';
import defaultImg from '~/Resources/images/defecto.jpg';
// Import Components
import Border from '~/Components/Border';
import SearchColorDetail from '~/Components/SearchColorDetail';
import ColorDetail from '~/Components/ColorDetail';
import DetailColorList from '~/Components/DetailColorList';
import LoadingIndicator from '~/Components/LoadingIndicator';
// Import Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';
// Import Styles
import './index.scss';

const { Title, Text } = Typography;

function DetailsPage(props) {
  const { setActiveProduct } = props;
  const [tyPath, setTyPath] = useState('');
  const [tyPathReverse, setTyPathReverse] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [activeImage, setActiveImage] = useState();
  const [color, setColor] = useState();
  const [moreProducts, setMoreProducts] = useState(true);
  const location = useLocation();
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('common');
  const [getPathName, setGetPathName] = useState('');
  const getCategoryType = type => {
    switch (type) {
      case 'hand_knitting':
        return 'H';
      case 'industrial_knitting':
        return 'I';
      default:
        return '';
    }
  };

  const getAllProducts = async () => {
    const fetchAllProducts = await Api.get('/api/products/products/search/', {
      params: {
        category__in: [getPathName, 'B'].toString(),
      },
    });
    return fetchAllProducts?.data?.results;
  };

  useQueries([
    {
      queryKey: [getPathName],
      queryFn: () => getAllProducts(),
      onSuccess: res => {
        setAllProducts(res);
      },
    },
  ]);

  const { isLoading, error, data: productData } = useQuery(
    ['fetchProductDetail', id],
    () => Api.get(`/api/products/products/${id}/detail_view/`)
  );

  useEffect(() => {
    const pathCategoryType = location.pathname.split('/');
    if (pathCategoryType[1]) {
      const type = getCategoryType(pathCategoryType[1]);
      setGetPathName(type);
      setTyPath(pathCategoryType[1]);
      setTyPathReverse(
        pathCategoryType[1] === 'industrial_knitting'
          ? 'hand_knitting'
          : 'industrial_knitting'
      );
    } else {
      setTyPathReverse('');
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (id !== null && productData) {
      const prod = productData.data;
      if (product === null || (product && product.id !== prod.id)){
        setActiveImage(tyPath === 'hand_knitting' ? prod.braid : prod.cone);
        setProduct(prod);
        setColor(prod.colour_set[0]);
        setActiveProduct(prod.name);
      }
    }
    if (error) {
      HandleErroMessage(error);
    }
  }, [id, productData, error, tyPath]);

  return (
    <>
      {isLoading && !product && !color && !activeImage ? (
        <LoadingIndicator />
      ) : (
        product &&
        color && (
          <>
            <div className="DetailsPage">
              <Row>
                <Col className="left-col" lg={8} md={24}>
                  {!UnderBreakpoint(screens, 'md') && (
                    <>
                      <div className="title-container">
                        <Title className="uppercase light" level={3}>
                          {t('Details')}
                        </Title>
                      </div>
                      <Border position="left" />
                    </>
                  )}

                  <Row
                    gutter={24}
                    justify="center"
                    className="image-container gutter-row"
                  >
                    <Link to={`/${tyPathReverse}/details/${product.id}`}>
                      <Text className="link">
                        {product.category === 'B'
                          ? tyPath === 'hand_knitting'
                            ? t('Available in Industrial Knitting')
                            : t('Available in Hand Knitting')
                          : ''}
                      </Text>
                    </Link>
                    <Col className="main" span={24}>
                      <Magnifier
                        src={activeImage}
                        width={240}
                        mgShowOverflow={false}
                        mgShape="square"
                      />
                    </Col>
                    {tyPath === 'hand_knitting' ? (
                      <>
                        {product.swatch && (
                          <Col onClick={() => setActiveImage(product.swatch)}>
                            <img
                              className="secondary flat"
                              src={product.swatch}
                              alt=""
                            />
                          </Col>
                        )}
                        {product.braid && (
                          <Col onClick={() => setActiveImage(product.braid)}>
                            <img
                              className="secondary flat"
                              src={product.braid}
                              alt=""
                            />
                          </Col>
                        )}
                      </>
                    ) : (
                      <>
                        {product.swatch && (
                          <Col onClick={() => setActiveImage(product.swatch)}>
                            <img
                              className="secondary flat"
                              src={product.swatch}
                              alt=""
                            />
                          </Col>
                        )}
                        {product.cone && (
                          <Col onClick={() => setActiveImage(product.cone)}>
                            <img
                              className="secondary"
                              src={product.cone}
                              alt=""
                            />
                          </Col>
                        )}
                      </>
                    )}
                    {!product.swatch && !product.cone && (
                      <Col>
                        <img
                          className="secondary flat"
                          src={defaultImg}
                          alt=""
                        />
                      </Col>
                    )}
                  </Row>
                  {UnderBreakpoint(screens, 'md') && (
                    <Row>
                      <Title className="title uppercase f500" level={3}>
                        {product.name}
                      </Title>
                    </Row>
                  )}
                  <Row className="detail-texts gutter-row">
                    <Col className="detail-text-col" span={24}>
                      <Text className="light-text f500">
                        {t('Composition')}
                      </Text>
                      <Text className="detail-text f500">
                        {product.composition}
                      </Text>
                    </Col>
                    <Col className="detail-text-col" span={24}>
                      <Text className="light-text f500">
                        {t('Metric number')}
                      </Text>
                      <Text className="detail-text f500">
                        {product.metric_number.name}
                      </Text>
                    </Col>
                    <Col className="detail-text-col" span={24}>
                      <Text className="light-text f500">
                        {t('Minimun order')}
                      </Text>
                      <Text className="detail-text f500">
                        {tyPath === 'hand_knitting'
                          ? `${t('6 kg per colour, 30 kg per product')}`
                          : `${t('1 kg')}`}
                      </Text>
                    </Col>
                    {tyPath === 'hand_knitting' && (
                      <Col className="detail-text-col" span={24}>
                        <Text className="light-text f500">{t('Weight')}</Text>
                        <Text className="detail-text f500">
                          {tyPath === 'hand_knitting'
                            ? i18n.language === 'es'
                              ? `${product.weight_hand_knitting.name_es}`
                              : `${product.weight_hand_knitting.name_en}`
                            : i18n.language === 'es'
                            ? `${product.weight_industrial_knitting.name_es}`
                            : `${product.weight_industrial_knitting.name_en}`}
                        </Text>
                      </Col>
                    )}
                    <Col className="detail-text-col" span={24}>
                      <Text className="light-text f500">
                        {tyPath === 'hand_knitting'
                          ? `${t('Gauge')}`
                          : `${t('Suggested Needle')}`}
                      </Text>
                      <Text className="detail-text f500">
                        {tyPath === 'hand_knitting'
                          ? `${product.suggested_needle_hand_knitting?.name}`
                          : `${product.suggested_needle_industrial_knitting?.name}`}
                      </Text>
                    </Col>
                  </Row>
                  {tyPath === 'hand_knitting' && (
                    <Row className="detail-notes gutter-row">
                      <Col className="detail-text-col" span={24}>
                        <Text className="light-text f500">{t('Notes')}:</Text>
                      </Col>
                      <Col className="detail-text-col" span={24}>
                        <Text className="light-text f500">
                          {t(
                            'To labeling, please contact your sales representative.'
                          )}
                        </Text>
                      </Col>
                    </Row>
                  )}
                  {UnderBreakpoint(screens, 'md') && (
                    <Row className="detail-notes gutter-row">
                      <Col className="detail-text-col" span={24}>
                        <Text className="bold-text">
                          {t('Click to pick your colour')}:
                        </Text>
                      </Col>
                    </Row>
                  )}
                </Col>

                <Col className="left-col" lg={8} md={24}>
                  {!UnderBreakpoint(screens, 'md') && (
                    <>
                      <div className="title-container">
                        <Title className="uppercase light" level={3}>
                          {t('Colors')}
                        </Title>
                      </div>
                      <Border position="middle" />
                    </>
                  )}
                  <div className="colors-row">
                    <DetailColorList
                      colors={product.colour_set}
                      onColorClick={c => setColor(c)}
                      productData={{
                        productID: product.id,
                        name: product.name,
                        image: product.image,
                        minimumOrder: product.minimum_order,
                      }}
                    />
                  </div>
                </Col>

                {!UnderBreakpoint(screens, 'md') && (
                  <Col lg={8} md={24}>
                    <div className="title-container">
                      <Title className="uppercase light" level={3}>
                        {color.code}
                      </Title>
                    </div>
                    <Border position="right" />
                    <div className="gutter-row">
                      <ColorDetail
                        colorID={color.id}
                        productData={{
                          name: product.name,
                          image: product.cone,
                          minimumOrder: product.minimum_order,
                          colorThumbnail: color.photo,
                          category: product.category,
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row
                className="bordered-row"
                justify={
                  UnderBreakpoint(screens, 'md') ? 'space-between' : 'end'
                }
                gutter={8}
              >
                <Col>
                  <Button
                    target="_blank"
                    href={
                      process.env.REACT_APP_SERVER_LINK +
                      'product_catalog/' +
                      product.id
                    }
                    type="primary"
                  >
                    {t('Download PDF')}
                  </Button>
                </Col>
              </Row>
              <Row
                className="bordered-row-no-margin"
                justify="space-between"
                gutter={8}
              >
                <Col>
                  <Title
                    className="uppercase light"
                    level={UnderBreakpoint(screens, 'md') ? 5 : 3}
                  >
                    {t('MORE PRODUCTS')}
                  </Title>
                </Col>
                {moreProducts ? (
                  <Button
                    type="text"
                    onClick={() => setMoreProducts(false)}
                    className="btnOpt"
                    icon={<CaretUpOutlined />}
                  />
                ) : (
                  <Button
                    type="text"
                    onClick={() => setMoreProducts(true)}
                    icon={<CaretDownOutlined />}
                  />
                )}
              </Row>
              {moreProducts && (
                <Row>
                  {Children.toArray(
                    allProducts.map((item, index) => (
                      <Col
                        xs={24}
                        md={8}
                        className={index % 2 === 0 && 'border-right'}
                      >
                        <SearchColorDetail product={item} tyPath={tyPath} />
                      </Col>
                    ))
                  )}
                </Row>
              )}
            </div>
          </>
        )
      )}
    </>
  );
}

DetailsPage.propTypes = {
  setActiveProduct: PropTypes.func.isRequired,
  setProductCategory: PropTypes.func.isRequired,
};

export default DetailsPage;
