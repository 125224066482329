// Import core modules
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Magnifier from 'react-magnifier';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Col,
  Row,
  Typography,
  Input,
  Select,
  Space,
  Alert,
  notification,
  Checkbox,
  Modal,
  Tag
} from 'antd';
// Context
import { CartContext, CART_ACTIONS } from '~/Contexts/CartProvider';
// Import Components
import LoadingIndicator from '~/Components/LoadingIndicator';
// Import  Utils
import { HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';
// Import Images
import DonutBall from '~/Resources/images/ic_donut_ball.svg';
import PeanutBall from '~/Resources/images/ic_peanut_ball.svg';
import TwistedHank from '~/Resources/images/ic_twisted_hank.svg';
import Cone from '~/Resources/images/ic_cone.svg';
// Import Styles
import './index.scss';

const { Text } = Typography;
const { Option } = Select;

function ColorDetail(props) {
  const [tyPath, setTyPath] = useState('');
  const { colorID, productData, inPopover } = props;
  const [, dispatch] = useContext(CartContext);
  const { t } = useTranslation('common');
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState(0);
  const [presentationValue, setPresentationValue] = useState('');
  const [validateOrder, setValidateOrder] = useState(true);
  const [validateRestock, setValidateRestock] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [presentationWeigthValue, setPresentationWeigthValue] = useState(
    '25 g'
  );
  const [notPresentation, setNotPresentation] = useState(false);
  const [weightValidator, setWeightValidator] = useState(false);
  const location = useLocation();
  const { isLoading, error, data: colorData } = useQuery(
    ['fetchColor', colorID],
    () => Api.get(`/api/products/colours/${colorID}/`)
  );

  function onlyNumbers(e) {
    const key = window.event ? e.which : e.keyCode;
    if (key < 46 || key > 57) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    const pathCategoryType = location.pathname.split('/');
    if (pathCategoryType[1]) {
      setTyPath(pathCategoryType[1]);
    }
  }, [location]);

  useEffect(() => {
    if (tyPath === 'industrial_knitting') {
      setPresentationWeigthValue('1 kg');
      setPresentationValue('Cone');
      setQuantity(1);
    } else {
      setQuantity(6);
    }
  }, [tyPath]);

  useEffect(() => {
    if (colorData) {
      setColor(colorData.data);
    }
    if (error) {
      HandleErroMessage(error);
    }
  }, [colorData, error]);

  useEffect(() => {
    if (error) {
      console.log("hubo un error");
    }
    else {
      if (color.restock_set && color.restock_set[0]) {
        setValidateRestock(true);
      }
    }
  }, [colorData, color]);

  useEffect(() => {
    if (
      color.stock_set &&
      color.restock_set[0] &&
      (quantity > color.stock_set[0].amount || color.stock_set.length === 0)
    ) {
      setValidateOrder(false);
      setOpenModal(false);
    } else if (quantity > color.stock_set) {
      setValidateOrder(false);
      setOpenModal(false);
    } else if (
      color.stock_set !== undefined &&
      quantity > color.stock_set[0].amount
    ) {
      setValidateOrder(false);
      setOpenModal(false);
    } else {
      setValidateOrder(true);
      setOpenModal(true);
    }
  }, [color, quantity]);

  const addItemToCart = product => {
    if (tyPath === 'hand_knitting') {
      if (product.quantity < 6) {
        setWeightValidator(true);
      } else {
        setWeightValidator(false);
        if (tyPath === 'hand_knitting' && presentationValue === '') {
          setNotPresentation(true);
        } else {
          dispatch({
            type: CART_ACTIONS.ADD_ITEM,
            payload: product,
          });
          setNotPresentation(false);
          notification.success({
            message: t('Ok'),
            description: t('Product added to shopping cart'),
            placement: 'bottomLeft',
            className: 'success-notification',
          });
        }
      }
    } else if (tyPath === 'industrial_knitting') {
      if (product.quantity < 1) {
        setWeightValidator(true);
      } else {
        setWeightValidator(false);
        dispatch({
          type: CART_ACTIONS.ADD_ITEM,
          payload: product,
        });
        setNotPresentation(false);
        notification.success({
          message: t('Ok'),
          description: t('Product added to shopping cart'),
          placement: 'bottomLeft',
          className: 'success-notification',
        });
      }
    }
  };

  function onChange(e) {
    setPresentationValue(e.target.value);
  }

  const handleChangeWeight = e => {
    setPresentationWeigthValue(e);
  };

  return (
    <>
      {isLoading && !color ? (
        <LoadingIndicator small />
      ) : (
        <div
          className={
            inPopover
              ? 'ColorList-color-detail'
              : 'ColorList-color-detail in-detail'
          }
        >
          <Row className="fiber-image-container">
            <Magnifier src={color.photo} width={inPopover ? 170 : 220} />
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <Space className="stock-details" direction="vertical">
                <Text className="subtitle">{t('Current stock')}</Text>
                <Text className="light-text">
                  {color.restock_set && color.restock_set[0]
                    ? `${t('Next reposition')} (${moment(
                      color.restock_set[0].date
                    ).format('MMM Do YYYY')})`
                    : `${t('Next reposition (Not confirmed yet)')}`}
                </Text>
              </Space>
            </Col>
            <Col>
              <Space className="stock-details" direction="vertical">
                <Text className="subtitle">
                  {color.stock_set && color.stock_set[0]
                    ? color.stock_set[0].amount
                    : 0}{' '}
                  kg
                </Text>
                {validateRestock && (
                  <Text className="light-text">
                    {color.restock_set[0]
                      ? color.restock_set[0].amount + 'kg'
                      : ''}{' '}
                  </Text>
                )}
              </Space>
            </Col>
          </Row>
          <Row
            className="my-order input-number-wrapper"
            justify="space-between"
            align="middle"
          >
            <Col>
              <Text className="subtitle">{t('Presentation weight')}</Text>
            </Col>
            <Col>
              {tyPath === 'hand_knitting' ? (
                <Select
                  className="presentation-weight"
                  defaultValue="25 g"
                  onChange={handleChangeWeight}
                >
                  <Option value="25 g">25 g</Option>
                  <Option value="50 g">50 g</Option>
                  <Option value="10 g">100 g</Option>
                  <Option value="20 g">200 g</Option>
                </Select>
              ) : (
                <Text className="subtitle">1 kg</Text>
              )}
            </Col>
          </Row>
          <Col align="middle">
            <Row
              className="my-order input-number-wrapper"
              justify="space-between"
              align="middle"
            >
              <Col>
                <Text className="subtitle">{t('My order')}</Text>
              </Col>
              <Col className="input-container">
                <Input
                  className="input-number"
                  value={quantity}
                  onChange={val => setQuantity(val.target.value)}
                  onKeyPress={e => onlyNumbers(e)}
                ></Input>
                <div className="input-text">kg</div>
              </Col>
            </Row>
            {tyPath === 'hand_knitting'
              ? weightValidator && (
                <Typography className="text-error">
                  {t(
                    'Order amount must be 6kg per color minimum if is hand knitting'
                  )}
                </Typography>
              )
              : weightValidator && (
                <Typography className="text-error">
                  {t(
                    'Order amount must be 1kg per color minimum if is industrial knitting'
                  )}
                </Typography>
              )}
          </Col>

          <Row justify="space-between" align="middle">
            <Col>
              <Space className="stock-details" direction="vertical">
                <Text className="subtitle">{t('Presentation')}</Text>
              </Space>
            </Col>

          </Row>

          <Row justify="space-between" align="middle">
            <Col>
              <Space className="stock-details" direction="vertical">
                {color.tag && (
                  <Tag color="#2db7f5" style={{
                    marginTop: '10px',
                  }}>{color.tag}</Tag>
                )}
              </Space>
            </Col>

          </Row>


          <Col align="middle">
            <Row justify="space-between" align="middle">
              <Col className="presentation-ctn">
                {tyPath === 'hand_knitting' ? (
                  <>
                    <Col className="presentation-item" span={8}>
                      <Checkbox
                        value="Donut Ball"
                        checked={presentationValue === 'Donut Ball'}
                        onChange={onChange}
                      >
                        <img
                          src={DonutBall}
                          alt="Donut Ball"
                          className="secondary"
                        />
                        <Text className="light-text f500">
                          {t('Donut Ball')}
                        </Text>
                      </Checkbox>
                    </Col>
                    <Col className="presentation-item" span={8}>
                      <Checkbox
                        value="Peanut Ball"
                        checked={presentationValue === 'Peanut Ball'}
                        onChange={onChange}
                      >
                        <img
                          src={PeanutBall}
                          alt="Peanut Ball"
                          className="secondary"
                        />
                        <Text className="light-text f500">
                          {t('Peanut Ball')}
                        </Text>
                      </Checkbox>
                    </Col>
                    <Col className="presentation-item" span={8}>
                      <Checkbox
                        value="Twisted Hank"
                        checked={presentationValue === 'Twisted Hank'}
                        onChange={onChange}
                      >
                        <img
                          src={TwistedHank}
                          alt="Twisted Hank"
                          className="secondary"
                        />
                        <Text className="light-text f500">
                          {t('Twisted Hank')}
                        </Text>
                      </Checkbox>
                    </Col>
                  </>
                ) : (
                  <Col className="presentation-item">
                    <img src={Cone} alt="Cone" className="secondary" />
                    <Text className="light-text f500">{t('Cone')}</Text>
                  </Col>
                )}
              </Col>
            </Row>
            {notPresentation && (
              <Typography className="text-error">
                {t('You must choose a presentation')}
              </Typography>
            )}
          </Col>
          <Row className="my-order" gutter={4} justify="end" align="middle">
            {!validateOrder ? (
              <Col className="stock-alert" span={24}>
                <Row
                  className="stock-alert"
                  gutter={4}
                  justify="end"
                  align="middle"
                >
                  <Alert
                    message={t('The order is greater than the current stock')}
                    type="warning"
                  />
                </Row>
              </Col>
            ) : null}
            <div className="decorative-space">
              <button
                className="primary-button"
                onClick={() => {
                  const product = {
                    name: productData.name,
                    image: productData.image,
                    minimumOrder: productData.minimumOrder,
                    colorID: color.id,
                    colorCode: color.code,
                    presentation: presentationValue,
                    colorImage: productData.colorThumbnail,
                    presentationWeigthValue: presentationWeigthValue,
                    quantity,
                  };
                  addItemToCart(product);
                }}
                disabled={!validateOrder}
              >
                <span className="primary-button__text">{t('Add to cart')}</span>
              </button>
            </div>
          </Row>
          <Modal
            visible={!openModal}
            onCancel={() => setOpenModal(true)}
            footer={null}
            width={800}
          >
            {t(
              'The colour you have chosen is out of stock at present. Please contact us for more information'
            )}
          </Modal>
        </div>
      )}
    </>
  );
}
ColorDetail.propTypes = {
  colorID: PropTypes.number.isRequired,
  productData: PropTypes.shape({
    name: PropTypes.string,
    minimumOrder: PropTypes.number,
    image: PropTypes.string,
    colorThumbnail: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
  inPopover: PropTypes.bool,
};
ColorDetail.defaultProps = {
  inPopover: false,
};

export default ColorDetail;
