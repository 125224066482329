// Import core modules
import React from 'react';

// Import Locals
import TermsConditions from '~/Components/TermsConditions';
import './index.scss';

function CookiesPolicy() {
  return <TermsConditions typeModal="" />;
}

export default CookiesPolicy;
