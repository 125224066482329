import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TermsEn from './TermsLanguages/TermsEn';
import TermsEs from './TermsLanguages/TermsEs';
import CookiesEn from './CookiesLanguages/CookiesEn';
import CookiesEs from './CookiesLanguages/CookiesEs';

const TermsConditions = ({ typeModal }) => {
  const { i18n } = useTranslation('common');

  return (
    <>
      {i18n.language === 'en' ? (
        typeModal === 'terms' ? (
          <TermsEn />
        ) : (
          <CookiesEn />
        )
      ) : typeModal === 'terms' ? (
        <TermsEs />
      ) : (
        <CookiesEs />
      )}
    </>
  );
};

TermsConditions.propTypes = {
  typeModal: PropTypes.string.isRequired,
};
export default TermsConditions;
