// Import core modules
import React from 'react';
import PropTypes from 'prop-types';

// Import Components

// Import Styles
import './index.scss';

function ColorCircle(props) {
  const { img, code, big } = props;
  return (
    <div className={big ? 'ColorCircle-big' : 'ColorCircle-div'}>
      <div
        className="color-div"
        style={{ background: `url(${img}) no-repeat`, backgroundSize: 'cover' }}
      />
      <p>{code}</p>
    </div>
  );
}

ColorCircle.propTypes = {
  img: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  big: PropTypes.bool,
};

ColorCircle.defaultProps = {
  big: false,
};

export default ColorCircle;
