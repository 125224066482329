// Import core modules
import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Divider } from 'antd';

// Import Components

// Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Styles
import './index.scss';

function Border(props) {
  const { position } = props;
  const screens = useBreakpoint();

  return (
    <div
      className={
        UnderBreakpoint(screens, 'md')
          ? 'Border-default'
          : {
              left: 'Border-left',
              right: 'Border-right',
              middle: 'Border-middle',
              default: 'Border-default',
            }[position]
      }
    >
      <Divider className="Border-divider" />
    </div>
  );
}

Border.propTypes = {
  position: PropTypes.string.isRequired,
};

export default Border;
