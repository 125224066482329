import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ConfigProvider } from 'antd';

// Import Custom Contexts
import { CartProvider } from '~/Contexts/CartProvider';
import { UserProvider } from '~/Contexts/UserProvider';

import './styles.scss';
import App from '~/Pages/App/App';

import * as serviceWorker from './serviceWorker';

// Trasnlations
import esTranslation from '~/Translations/es/common.json';
import enTranslation from '~/Translations/en/common.json';

dotenv.config();

const queryClient = new QueryClient();

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  keySeparator: false,
  resources: {
    en: {
      common: enTranslation,
    },
    es: {
      common: esTranslation,
    },
  },
});
const validateMessages = {
  required: i18next.t("'${label}'") + ' ' + i18next.t('is required!'),
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <UserProvider>
          <CartProvider>
            <ConfigProvider form={{ validateMessages }}>
              <App />
            </ConfigProvider>
          </CartProvider>
        </UserProvider>
      </BrowserRouter>
    </I18nextProvider>
  </QueryClientProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
