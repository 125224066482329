import React from 'react';

// import images
import CookiesImage from '~/Resources/images/CookiesImage.png';

// import styles
import './styles.scss';

const CookiesEs = () => {
  return (
    <div className="cookies">
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '30px' }}>COOKIES POLICY</p>
        <p style={{ fontSize: '25px' }}>
          <b>INCA TOPS S.A.</b>
        </p>
      </div>
      <p style={{ float: 'right' }}>Arequipa, October 2020</p>
      <p style={{ marginTop: '5rem' }}>
        <b>1. SCOPE</b>
      </p>
      <p className="item-content">
        This “Web Privacy Policy in the Protection of Personal Data” will apply
        to all of the databases and/or archives that contain personal data that
        may be subject to processing by INCA TOPS S.A. (hereinafter known as
        INCA TOPS) on its website. INCA TOPS has the following information:
      </p>
      <p className="sub-item-content">
        <b>Name:</b> INCA TOPS S.A.
      </p>

      <p className="sub-item-content">
        <b>RUC N°:</b> 20100199743
      </p>

      <p className="sub-item-content">
        <b>Address:</b> Avenida Miguel Forga 348 Z.I. Parque Industrial,
        Arequipa
      </p>

      <p>
        <b>2. INTRODUCTION</b>
      </p>
      <p className="item-content">
        INCA TOPS describes in this document the “Cookies Policy” that regulates
        the website with the URL
        <b> https://www.incatops.com/es/</b>, <b>https://amanoyarns.com/</b>, y{' '}
        <b>https://pacomarca.com/es/ </b>
        hereinafter know as the Web, with the object of guaranteeing the privacy
        of the Users of the Internet, hereinafter known as the User.
      </p>
      <p className="item-content">
        We inform the Users that on the Web we use first-party and third-party
        cookies.
      </p>
      <p className="item-content">
        These cookies allow us to facilitate use and navigation, guarantee
        access to certain functionalities and additionally, help us to improve
        the quality of the Web according to the habits and browsing styles of
        the Users.
      </p>
      <p className="item-content">
        INCA TOPS uses strictly necessary and essential cookies so that you may
        use the Web and to allow you to browse freely, using secure areas or
        customized options.
      </p>
      <p>
        <b>3. WHAT ARE COOKIES?</b>
      </p>
      <p className="item-content">
        Cookies are small text files that websites store on your computer, smart
        phone, tablet, or any other device that is able to access the internet.
      </p>
      <p>
        <b>4. WHAT ARE THEY USED FOR?</b>
      </p>
      <p className="item-content">
        Cookies are used to recognize the Users’ device when they visit the Web
        again, facilitating its use by remembering the Users preferences and
        browser configuration (e.g. language, country, etc.). They are also used
        to improve the services that we offer, and to be able to collect
        statistical information that enables us to understand how Users used our
        Web and helps us to improve its structure and content.
      </p>
      <p className="item-content">
        Some cookies are strictly necessary so that the Web functions correctly,
        and other cookies are to improve performance and User experience.
      </p>
      <p className="item-content">
        Cookies are only associated to one anonymous User and their device with
        access to the internet, and do not provide references that would allow
        the deriving of the User’s personal data. Cookies cannot damage your
        device and are also very helpful, since they help us to identify and
        resolve errors.
      </p>
      <p className="item-content">
        We show information about our Cookies Policy at the bottom of the
        webpage each time the Web is accessed.
      </p>
      <p className="item-content">
        Given this information, the User can take the following actions:
      </p>
      <p className="sub-item-content">
        i. Accept. This notice will not be displayed again during the present
        session.
      </p>
      <p className="sub-item-content">
        ii. Close. The notice is closed on the current page, but by accessing
        any other page from the Web the notice will be displayed again at the
        top of the page.
      </p>

      <p className="sub-item-content">
        iii. Modify your settings. You will be able to change your browser
        settings, but this will not prevent the cookie notice from being
        displayed again when new pages of the Web are accessed.
      </p>

      <p>
        <b>5. TYPES OF COOKIES</b>
      </p>
      <p className="item-content">
        Depending on the entity that manages the Web from where the cookies are
        sent, and where the obtained data is processed, the following can be
        distinguished:
      </p>
      <p className="sub-item-content">
        i. First-party cookies: Are those that are sent to the User’s Internet
        access device from the managed Web by the owner of the website, and by
        which the requested service is provided to the Internet User.
      </p>
      <p className="sub-item-content">
        ii. Third-party cookies: Are those that are sent to the User’s Internet
        access device from the Web but are not managed by the owner of the
        website, but rather by another entity that processes the data obtained
        through cookies.
      </p>
      <p className="item-content">
        Depending on the time period that the cookies remain active on the
        User’s Internet access device, the following can be distinguished:
      </p>
      <p className="sub-item-content">
        i. Session Cookies: Are a type of cookie designed to collect and store
        data while the User accesses the Web. They are often used to store
        information that is kept for the provision of the requested service to
        the User on one occasion (the session that is being used in that
        moment).
      </p>
      <p className="sub-item-content">
        ii. Persistent Cookies: Are a type of cookie in which the data continue
        to be stored on the User’s Internet access device and can be accessed
        and processed during a period defined by the person or entity
        responsible for the cookie, which can range from a few minutes to
        several years.
      </p>
      <p className="item-content">
        Depending on the purpose for which the data obtained through cookies is
        processed, the following can be distinguished:
      </p>
      <p className="sub-item-content">
        i. Technical Cookies: Are those that are essential and strictly
        necessary for the proper functioning of the Web and for the use of the
        different options and services it offers. This type, among others, are
        those that serve to maintain the session, response time management,
        performance, or option validation, use security elements, or share
        content with social networks.
      </p>
      <p className="sub-item-content">
        ii. Personalization Cookies: Are those that allow the User to access the
        service with some general, pre-defined characteristics within a range of
        criteria on the User’s device, e.g., language, the type of browser that
        is used to access the service, the regional configuration from where the
        service is accessed, etc.
      </p>
      <p className="sub-item-content">
        iii. Geolocation Cookies: Are used to find out from which country the
        User is requesting a service. These cookies is completely anonymous and
        are only used to help target content to the User’s location.
      </p>
      <p className="sub-item-content">
        iv. Analysis Cookies: Are those that allow the owner to monitor and
        analyze the behavior of the Web Users. The information gathered through
        this type of cookie is used in measuring the activity of the Web, and
        for the creation of User browser profiles, with the purpose of
        introducing improvements in the analysis of the usage data made by the
        service Users.
      </p>
      <p className="sub-item-content">
        v. Advertising cookies: Are those that allow the most effective
        management of the advertising spaces that, where appropriate, the owner
        has included on the Web from which the requested service is provided,
        based upon criteria such as edited content, or how often advertisements
        are shown.
      </p>
      <p className="sub-item-content">
        vi. Behavioral advertising cookies: Are those that allow the most
        effective management of the advertising spaces that, where appropriate,
        the owner has included on the Web from which the requested service is
        provided. These cookies store information about the behavior of the
        Users, obtained by the continuous observation of their browsing habits,
        which allows the creation of a specific profile in order to show
        specific advertisements.
      </p>
      <p className="sub-item-content">
        vii. Login cookies: Are those that are generated once the User has
        logged in or has previously opened a session and are used to identify
        the User in the services. So, if the service, browser, or computer is
        closed, and at another time or day the User returns to the service, they
        will be identified, facilitating their browsing experience without
        having to login again. This functionality can be canceled if the User
        presses the “log out” button, so that the cookie is deleted and the next
        time that they use the service, they will have to login to be
        identified.
      </p>
      <p>
        <b>6. WHAT TYPE OF COOKIES DOES THIS WEBSITE USE?</b>
      </p>
      <p className="item-content">
        The following cookies do not identify Users personally. They only
        provide anonymous statistical information about the browsing on this
        Web. However, the User can disable them directly in the browser
        settings.
      </p>

      <p>
        <b>Inca Tops</b>
      </p>
      <table>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Time Period</th>
          <th scope="col">Purpose</th>
        </tr>

        <tr>
          <td>csrftoken</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Technical Cookies</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
      </table>
      <br />
      <p>
        <b>Pacomarca</b>
      </p>
      <table>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Time Period</th>
          <th scope="col">Purpose</th>
        </tr>

        <tr>
          <td>csrftoken</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Technical Cookies</td>
        </tr>
        <tr>
          <td>__utma</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>__utmc</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>__utmz</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>__utmt</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>__utmtb</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
      </table>
      <br />
      <p>
        <b>Amano Yarns</b>
      </p>
      <table>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
          <th scope="col">Time Period</th>
          <th scope="col">Purpose</th>
        </tr>

        <tr>
          <td>qtrans_front_language</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Technical Cookies</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
        <tr>
          <td>_gat</td>
          <td>Third-Party Cookies</td>
          <td>Persistent Cookies</td>
          <td>Analysis Cookies</td>
        </tr>
      </table>
      <br />
      <img src={CookiesImage} style={{ width: '100%' }} alt="Cookies Policy" />
      <br />
      <p>
        <b>7. COOKIE MANAGEMENT</b>
      </p>
      <p className="item-content">
        By providing this policy, we set forth the commitment acquired by our
        Users in the use of cookies. We provide you access to this information
        so that you may understand what types of cookies we use, and why we use
        them. With this, we intend to provide transparency regarding the
        processed data about the browsing made from the User’s device on the
        Web.
      </p>
      <p className="item-content">
        If you accept our cookies, it allows for improvements to the Web of INCA
        TOPS in order to offer optimum access, and to provide you a more
        effective and personalized service.
      </p>
      <p className="item-content">
        Furthermore, you may configure your browser to establish that only
        trusted websites or pages in which you are browsing at the moment are
        able to manage cookies which allows you to select your preferences.
      </p>
      <p className="item-content">
        If you as the User decide to not authorize the processing of cookies by
        indicating your disagreement, then we will only use technical cookies
        since they are essential for the browsing of our Web. Therefore, we
        would not store any cookie. If you continue browsing our Web without
        denying authorization, it implies that you accept the use of cookies.
      </p>
      <p className="item-content">
        Keep in mind that, if you reject or delete cookies from browsing the
        Web, we will not be able to maintain your preferences, some
        characteristics of the webpages will not be operational, we will not be
        able to offer you personalized services, and each time you browse our
        Web we will have to ask for the authorization to use cookies again.
      </p>
      <p className="item-content">
        If, even then, you still decide to modify the access settings of the
        webpage, you should know that it is possible to delete cookies or
        prevent this information from being recorded on your device at any time
        by modifying the parameters in the browser settings.
      </p>
      <p className="item-content">
        To block or disable the cookies, the User must activate the browser
        settings, so that it blocks the installation of all cookies or some of
        them. In practice, most browsers warn of the presence of cookies, or
        reject them automatically. If they are rejected, the User will still be
        able to use our Web, although the use of some service may be limited,
        and therefore the User experience may be less satisfactory.
      </p>
      <p className="item-content">
        To access the cookies settings in the main internet browsers, do the
        following:
      </p>
      <p className="sub-item-content">
        <b>
          i. Safari: Preferences -&gt; Security. For more information, you can
          consult Apple support or the browser help at
          www.apple.com/es/privacy/use-of-cookies
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          ii. Firefox: Tools -&gt; Options -&gt; Privacy -&gt; History -&gt;
          Custom Settings. For more information, you can consult Mozilla support
          or the browser help at support.mozilla.org/es/kb/Borrar cookies
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          iii. Chrome: Settings -&gt; Advanced Settings -&gt; Privacy -&gt;
          Content Settings. For more information you can consult Google support
          or the browser help at support.google.com/chrome/answer/95647
        </b>
      </p>
      <p>
        <b>8. OTHER POINTS</b>
      </p>
      <p className="item-content">
        To make any kind of inquiry regarding this policy, you can contact the
        following email address:{' '}
        <a href="mailto:derechosarco@incatops.com">derechosarco@incatops.com</a>
      </p>
      <p>
        <b>9. EXERCISE OF RIGHTS</b>
      </p>
      <p className="item-content">
        Users who have provided their personal data to INCA TOPS can contact the
        following, with the purpose of exercising their rights of information,
        access, updating, rectification, deletion, to prevent the furnishing of
        their personal data, to oppose the processing or the processing
        objective of the data, under the terms set forth in the current Peruvian
        legislation.
      </p>
      <p className="item-content">
        In order to exercise these rights, users can address, through any medium
        that is able to prove the sending and receipt of the request, to the
        following: Avenida Miguel Forga 348 Z.I. Parque Industrial, Arequipa, or
        by writing to the email derechosarco@incatops.com with the subject
        “Personal Data Protection,” specifying the data, proving their identity,
        and the reasons for the request.
      </p>
      <p className="item-content">
        Additionally, the owners of the personal data may present a claim to the
        Autoridad Nacional de Protección de Datos Personales, addressing the
        Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle
        Scipión Llona N° 350, Miraflores, Lima, Perú, to consider whether they
        were not attended to in the exercise of their rights.
      </p>
      <p>
        <b>10. VALIDITY AND CHANGES TO THIS COOKIES POLICY</b>
      </p>
      <p className="item-content">
        INCA TOPS’ Cookies Policy has been updated in January of 2021.
      </p>
      <p className="item-content">
        INCA TOPS may change this Cookies Policy to meet new legal provisions,
        regulations, jurisprudence or with the purpose to adapt said policy to
        the instructions dictated by the Dirección de Protección de Datos
        Personales, as well as first-party institutional criteria.
      </p>
      <p className="item-content">
        Therefore, given that said policy may be updated periodically, we
        suggest to our Users that they regularly review this policy. It can be
        found at{' '}
        <a href="https://incatops.com/en/cookiespolicy">
          www.incatops.com/en/cookiespolicy
        </a>
      </p>
    </div>
  );
};

CookiesEs.propTypes = {};
export default CookiesEs;
