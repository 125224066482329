import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button, Form, Input, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import jwt from 'jsonwebtoken';
// Context
import { UserContext, USER_ACTIONS } from '~/Contexts/UserProvider';

// Import Components

// Inport Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';
// Import Styles
import './index.scss';

const { Title, Text } = Typography;

const Login = ({ showPopover, setShowForget, setShowRegister }) => {
  const timeExpire = 60; // minutos que expirará el token

  const [, dispatch] = useContext(UserContext);

  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { t } = useTranslation('common');

  const saveToken = token => {
    const fToken = jwt.sign(
      {
        exp: Math.floor(Date.now() / 1000) + 60 * timeExpire,
        data: token,
      },
      process.env.REACT_APP_PRIVATE_KEY
    );

    dispatch({
      type: USER_ACTIONS.ADD_TOKEN,
      payload: fToken,
    });
  };

  const saveCanDownloadExcel = canDownloadExcel => {  

    dispatch({
      type: USER_ACTIONS.ADD_EXCEL_DOWNLOAD,
      payload: canDownloadExcel,
    });
  };

  const loginMutation = useMutation(
    loginData => Api.post('/api-token-auth/', loginData),
    {
      onError: error => {
        HandleErroMessage(error);
        notification.error({
          message: t('Something went wrong'),
          description: t('An error has occurred, please try again'),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
      },
      onSuccess: res => {
        saveToken(res.data.token);
        saveCanDownloadExcel(res.data.can_download_excel);        
        notification.success({
          message: t('Login successful'),
          placement: 'bottomLeft',
          className: 'success-notification',
        });
        showPopover(false);
      },
    }
  );

  const onSubmitForm = values => {
    const user = {
      email: values.email,
      password: values.password,
      username: values.email,
    };

    loginMutation.mutate(user);
  };

  const changeModalPass = () => {
    showPopover(false);
    setShowForget(true);
  };

  const changeModalReg = () => {
    showPopover(false);
    setShowRegister(true);
  };

  return (
    <div className="Login">
      <Row justify="center">
        <Col className="title-container">
          <Text className="text-hello">
            {t(
              'Hello friend! To get a request please sign in or create an Account!'
            )}
          </Text>
          <Title
            className="f500"
            level={UnderBreakpoint(screens, 'md') ? 4 : 3}
          >
            {t('Sign In')}
          </Title>
        </Col>

        <Col span={24}>
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            layout="inline"
            validateTrigger="onSubmit"
            size="large"
            onFinish={onSubmitForm}
          >
            <Row justify="end" className="form-container">
              <Col span={24}>
                <Form.Item
                  name="email"
                  label={t('Email')}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: 'email',
                      message: t('Enter a valid email'),
                    },
                  ]}
                >
                  <Input placeholder="E-mail" className="emailLog" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="password"
                  label={t('Password')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text>
                  {t('Do not have an account?, Register')}{' '}
                  <button
                    type="button"
                    className="redirect"
                    onClick={changeModalReg}
                  >
                    {t('here')}
                  </button>
                </Text>
              </Col>
              <Col span={24}>
                <Text>
                  {t('Forgot the password?, Click')}{' '}
                  <button
                    type="button"
                    className="redirect"
                    onClick={changeModalPass}
                  >
                    {t('here')}
                  </button>
                </Text>
              </Col>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loginMutation.isLoading}
              >
                {t('Login')}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

Login.propTypes = {
  showPopover: PropTypes.func.isRequired,
  setShowForget: PropTypes.func.isRequired,
  setShowRegister: PropTypes.func.isRequired,
};

export default Login;
