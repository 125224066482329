import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button, Form, Input, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// Import Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';

// Import Styles
import './index.scss';

const { Text } = Typography;

const ResetPassword = ({ showPopover, resetToken }) => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { t } = useTranslation('common');
  const history = useHistory();

  const quotationMutation = useMutation(
    quotationData =>
      Api.post('/api/users/password-reset/confirm/', quotationData),
    {
      onError: err => {
        HandleErroMessage(err);
        showPopover(true);
        notification.error({
          message: t('Something went wrong'),
          description: t(
            'Please verify your password has more than 8 characters and is alphanumeric'
          ),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
      },
      onSuccess: () => {
        notification.success({
          message: t('Your password has been reset'),
          description: t('Now you can sign in'),
          placement: 'bottomLeft',
          className: 'success-notification',
        });
        showPopover(false);
        history.push('/');
      },
    }
  );

  const onSubmitForm = async values => {
    const body = {
      password: values.password,
      token: resetToken,
    };
    quotationMutation.mutate(body);
  };

  return (
    <div className="Reset">
      <Col span={24} order={UnderBreakpoint(screens, 'md') ? 0 : 1}>
        <Row align="middle">
          {!UnderBreakpoint(screens, 'md') && (
            <Col className="title-container optima" span={24}>
              <Text className="f500" level={4}>
                {t('Enter a new password for your account')}
              </Text>
            </Col>
          )}
          <Col className="" span={24}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              validateTrigger="onSubmit"
              size="large"
              onFinish={onSubmitForm}
            >
              <Row justify="end" className="form-container">
                <Col span={24}>
                  <Form.Item
                    name="password"
                    label={t('New Password')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.Password placeholder={t('New Password')} />
                  </Form.Item>
                </Col>

                <Col
                  className="send-button forgot"
                  span={24}
                  order={UnderBreakpoint(screens, 'md') ? 2 : 2}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={quotationMutation.isLoading}
                  >
                    {t('Password recovery')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

ResetPassword.propTypes = {
  showPopover: PropTypes.func.isRequired,
};

export default ResetPassword;
