// Import core modules
import React, { useEffect, useState, Children } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Radio,
  Input,
  Space,
  Collapse,
  Checkbox,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useQuery, useQueries } from 'react-query';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// Import Components
import Border from '~/Components/Border';
import SearchColorDetail from '~/Components/SearchColorDetail';
import LoadingIndicator from '~/Components/LoadingIndicator';

// Import Utils
import { UnderBreakpoint, AddFilter } from '~/Utils/Functions';
import Api from '~/Utils/Api';

// Import Styles
import './index.scss';

const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const categories = [
  {
    id: 'B',
    name_en: 'Both',
    name_es: 'Ambos',
    banner: false,
  },
  {
    id: 'H',
    name_en: 'Hand Knitting',
    name_es: 'Tejido a mano',
    banner: true,
  },
  {
    id: 'I',
    name_en: 'Industrial Knitting',
    name_es: 'Tejido Industrial',
    banner: true,
  },
];
const subCategories = [
  {
    id: 'E',
    name_en: 'Essentials',
    name_es: 'Essentials',
    banner: false,
  },
  {
    id: 'V',
    name_en: 'Essentials Vibes',
    name_es: 'Essentials Vibes',
    banner: true,
  },
];

function SearchPage() {
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('common');

  const getCategoryName = id => {
    switch (id) {
      case 'H':
        return t('hand_knitting');
      case 'I':
        return t('industrial_knitting');
      default:
        return t('');
    }
  };

  return (
    <Row>
      {Children.toArray(
        categories.map(cat => (
          <>
            {cat.banner && (
              <>
                <Col xs={24} md={12} className="category-banner">
                  <div className="category-banner__text">
                    <Link to={`/${getCategoryName(cat.id)}/search`}>
                      <Text strong>
                        {i18n.language === 'es' ? cat.name_es : cat.name_en}
                      </Text>
                    </Link>
                  </div>
                </Col>
              </>
            )}
          </>
        ))
      )}
    </Row>
  );
}
export default SearchPage;
