// Import Styles
import './index.scss';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Row,
  Select,
  Typography,
  notification,
} from 'antd';
import {
  DeleteFilled,
  MailOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
// Import core modules
import React, { Children, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import Api from '~/Utils/Api';
// Import Components
import Border from '~/Components/Border';
// Context
import { CartContext } from '~/Contexts/CartProvider';
// Import Utils
import { HandleErroMessage } from '~/Utils/Functions';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import culqi from '~/Resources/images/culqi.png';
import paypal from '~/Resources/images/paypal.png';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

function PaymentPage() {
  const [state, dispatch] = useContext(CartContext);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [succes, setSucces] = useState(false);
  const { t, i18n } = useTranslation('common');
  const [errorMessage, setErrorMessage] = useState('');
  const [countries, setCountries] = useState([]);
  const [prices, setPrices] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [approveCaptcha, setApproveCaptcha] = useState(false);
  const [payment, setPayment] = useState('');
  const [quotation, setQuotation] = useState({});
  const { quotationId } = useParams();

  const sitekey = process.env.REACT_APP_GOOGLE_SITE_KEY;

  const updateQuotationMutation = useMutation(
    data => Api.patch(`/api/quotations/quotations/${quotationId}/`, data),
    {
      onError: error => {
        const errorMessagePost = error.response.data.non_field_errors[0];
        const otherError = t('An error has occurred, please try again');
        errorMessagePost !== ''
          ? setErrorMessage(errorMessagePost)
          : setErrorMessage(otherError);
        setSucces(false);
        // setShowErrorQuotation(true);
      },
      onSuccess: async () => {
        await Api.get(
          `/confirm-quotation/${quotationId}/${quotation.token}/${i18n.language}/`
        );

        setSucces(true);
      },
    }
  );

  const getCuotation = useQuery(
    'fetchQuotation',
    () => Api.get(`/api/quotations/quotations/${quotationId}`),
    {
      onSuccess: quotation => {
        setQuotation(quotation.data);
      },
      onError: () => {
        notification.error({
          message: t('Something went wrong'),
          description: t('Please sign in to continue the proccess'),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
      },
    }
  );

  useQuery('fetchCountries', () => Api.get('/api/products/shipping_rates/'), {
    onSuccess: countryData => {
      setCountries(countryData.data);
    },
    onError: () => {
      notification.error({
        message: t('Something went wrong'),
        description: t('Please sign in to continue the proccess'),
        placement: 'bottomLeft',
        className: 'error-notification',
      });
    },
  });

  useEffect(() => {
    let totalPrices = 0;
    let totalOrders = 0;
    if (quotation.item_set)
      quotation.item_set.forEach(c => {
        totalPrices += c.price * c.amount;
        totalOrders += c.amount;
      });
    setPrices(totalPrices);
    setTotalOrder(totalOrders);
  }, [quotation]);

  useEffect(() => {
    setTotal(shipping + prices);
  }, [shipping, prices]);

  const shippingMutation = useMutation(
    quotationData => Api.post('/api/products/shipping_rates/', quotationData),
    {
      onError: err => {
        HandleErroMessage(err);
        notification.error({
          message: t('Something went wrong'),
          description: t(
            'The selected country does not have a shipping price, please select another option.'
          ),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
        setShipping(0);
      },
      onSuccess: res => {
        setShipping(res.data.price);
      },
    }
  );

  const handleCountry = val => {
    const shippingRate = {
      weight: totalOrder,
      country: val,
    };
    form.setFieldsValue({ country: val });
    shippingMutation.mutate(shippingRate);
  };

  const onSubmitForm = async values => {
    const { name, country, city, zipCode, address } = values;

    const sendData = {
      shipping_full_name: name,
      shipping_country: form.getFieldValue('country'),
      shipping_city: city,
      shipping_zip: zipCode,
      shipping_address: address,
    };

    updateQuotationMutation.mutate(sendData);

    if (approveCaptcha) {
    }
  };

  return (
    <div className="PaymentPage">
      {succes ? (
        <div className="empty-container">
          <MailOutlined className="mail-icon" />
          <Text className="empty-text-bottom f500" level={4}>
            {t(
              'Thank you for confirming your order. Our sales executive will send the'
            )}
          </Text>
          <Text className="empty-text-bottom  f500" level={4}>
            {t(
              'corresponding documents to continue with the buying process within the next'
            )}
          </Text>
          <Text className="empty-text f500" level={4}>
            {t('48 hours.')}
          </Text>
          <Link to="/">
            <Button type="primary">{t('Back to home')}</Button>
          </Link>
        </div>
      ) : (
        <Row justify="center">
          <Col className="title-container optima" span={24}>
            <Title className="f500" level={4}>
              {t('Delivery Address')}
            </Title>
          </Col>
          <Col span={24}>
            <Border position="default" />
          </Col>
          <Col span={24}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              validateTrigger="onSubmit"
              size="large"
              onFinish={onSubmitForm}
            >
              <Row>
                <Col span={12} className="block-form">
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('Shipping Contact Full Name')} />
                  </Form.Item>
                </Col>
                <Col span={12} className="block-form">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={t('Country')}
                      className="width-100"
                      name="country"
                      onChange={handleCountry}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Children.toArray(
                        countries.map(country => (
                          <Option value={country.country}>
                            {country.country_name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className="block-form">
                  <Form.Item
                    name="city"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('City')} />
                  </Form.Item>
                </Col>
                <Col span={12} className="block-form">
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('Address')} />
                  </Form.Item>
                </Col>
                <Col span={12} className="block-form">
                  <Form.Item
                    name="zipCode"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('ZIP Code')} />
                  </Form.Item>
                </Col>
                <Col span={12} className="block-form">
                  <Card title={t('Subtotal')} className="card-total">
                    <List className="list-total">
                      <List.Item key="1">
                        <List.Item.Meta title={`${totalOrder} Kg`} />
                        {prices > 0 && <div> USD {prices}</div>}
                      </List.Item>
                      <List.Item key="2">
                        <List.Item.Meta title="Shipping" />
                        <Text>USD {shipping}</Text>
                      </List.Item>
                      <List.Item key="3">
                        <List.Item.Meta title="Total" />
                        <Text>USD {total}</Text>
                      </List.Item>
                    </List>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Border position="default" />
                </Col>
                <Col className="title-container optima" span={24}>
                  <Title className="f500" level={4}>
                    {t('Payment Method')}
                  </Title>
                </Col>
                <Col span={24}>
                  <Border position="default" />
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={12} className="payment">
                      <Form.Item>
                        <input
                          type="radio"
                          name="payment"
                          value="paypal"
                          id="paypal"
                          onChange={e => setPayment(e.target.value)}
                        />
                        <label htmlFor="paypal">
                          <img src={paypal} alt="" />
                        </label>
                      </Form.Item>
                      <Form.Item>
                        <input
                          type="radio"
                          name="payment"
                          value="culqi"
                          id="culqi"
                          onChange={e => setPayment(e.target.value)}
                        />
                        <label htmlFor="culqi">
                          <img src={culqi} alt="" />
                        </label>
                      </Form.Item>
                    </Col>
                    <Col span={12} className="confirm">
                      <ReCAPTCHA
                        sitekey={sitekey}
                        onChange={() => setApproveCaptcha(true)}
                        onErrored={() => setApproveCaptcha(false)}
                      />
                      <Button
                        type="primary"
                        onClick={() => {
                          form.submit();
                        }}
                      >
                        {t('Confirm the order')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PaymentPage;
