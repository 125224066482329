import React from 'react';

// import images
import IncaTopsLogoTerms from '~/Resources/images/IncaTopsLogoTerms.png';
import CookiesImage from '~/Resources/images/CookiesImage.png';

// import styles
import './styles.scss';

const CookiesEs = () => {
  return (
    <div className="cookies">
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '30px' }}>POLÍTICA DE COOKIES</p>
        <p style={{ fontSize: '25px' }}>
          <b>INCA TOPS S.A.</b>
        </p>
      </div>
      <p style={{ float: 'right' }}>Arequipa, Octubre 2020</p>
      <p style={{ marginTop: '5rem' }}>
        <b>1. ALCANCE</b>
      </p>
      <p className="item-content">
        Esta “Política de Privacidad Web en Protección de Datos Personales” se
        aplicará a todos los bancos de datos y/o archivos que contengan datos
        personales que sean objeto de tratamiento por parte de INCA TOPS S.A.
        (en adelante INCA TOPS) en su sitio Web, empresa que cuenta con la
        siguiente información:
      </p>
      <p className="sub-item-content">
        <b>Nombre:</b> INCA TOPS S.A.
      </p>
      <p className="sub-item-content">
        <b>RUC N°:</b> 20100199743
      </p>
      <p className="sub-item-content">
        <b>Domicilio: </b> Avenida Miguel Forga 348 Z.I. Parque Industrial,
        Arequipa
      </p>
      <p>
        <b>2. INTRODUCCIÓN</b>
      </p>
      <p className="item-content">
        INCA TOPS describe en este documento la “Política de Cookies” que regula
        el sitio web con URL
        <b> https://www.incatops.com/es/</b>, <b>https://amanoyarns.com/</b> y
        <b> https://pacomarca.com/es/</b>
        en adelante la Web, con el objetivo de garantizar la privacidad de los
        Usuarios de Internet, en adelante el Usuario.
      </p>
      <p className="item-content">
        Informamos a los Usuarios que en la Web utilizamos cookies propias.
      </p>
      <p className="item-content">
        Estas cookies nos permiten facilitar el uso y navegación, garantizar el
        acceso a determinadas funcionalidades y adicionalmente, nos ayudan a
        mejorar la calidad de la Web de acuerdo a los hábitos y estilos de
        navegación de los Usuarios.
      </p>
      <p className="item-content">
        INCA TOPS utiliza las cookies estrictamente necesarias y esenciales para
        que usted utilice la Web y le permita navegar libremente, utilizando
        áreas seguras u opciones personalizadas.
      </p>
      <p>
        <b>3. ¿QUÉ SON LAS COOKIES?</b>
      </p>
      <p className="item-content">
        Las cookies son pequeños archivos de texto que los sitios Web almacenan
        en su ordenador, celular inteligente (Smartphone), Tablet o cualquier
        otro dispositivo de acceso a Internet.
      </p>
      <p>
        <b>4. ¿PARA QUÉ SIRVEN?</b>
      </p>
      <p className="item-content">
        Las cookies sirven para reconocer el dispositivo de los Usuarios cuando
        vuelven a visitar la Web, facilitándole su uso recordando sus
        preferencias y configuración de navegación (p. ej. Idioma, país, etc.).
        También sirven para mejorar los servicios que ofrecemos y para poder
        recopilar información estadística que nos permite entender cómo los
        Usuarios utilizan nuestra Web y nos ayudan a mejorar su estructura y
        contenidos.
      </p>
      <p className="item-content">
        Algunas cookies son estrictamente necesarias para que la Web funcione
        correctamente y otras sirven para mejorar el rendimiento y su
        experiencia como Usuario.
      </p>
      <p className="item-content">
        Las cookies se asocian únicamente a un Usuario anónimo y su dispositivo
        de acceso a Internet y no proporcionan referencias que permitan deducir
        datos personales del Usuario. Las cookies no pueden dañar su dispositivo
        y además son muy útiles, puesto que nos ayudan a identificar y resolver
        errores.
      </p>
      <p className="item-content">
        Mostramos información sobre nuestra Política de Cookies en la parte
        inferior cada vez que se accede a la Web.
      </p>
      <p className="item-content">
        Ante esta información, el Usuario puede realizar las siguientes
        acciones:
      </p>
      <p className="sub-item-content">
        i. Aceptar. No se volverá a visualizar este aviso durante la presente
        sesión.
      </p>
      <p className="sub-item-content">
        ii. Cerrar. Se oculta el aviso en la presente página, pero al acceder a
        cualquier otra página de la Web se volverá a mostrar el aviso en la
        parte superior del mismo.
      </p>
      <p className="sub-item-content">
        iii. Modificar su configuración. Podrá modificar la configuración de su
        navegación, pero ello no evitará que se muestre el aviso sobre cookies
        al acceder a nuevas páginas de la Web.
      </p>
      <p>
        <b>5. TIPOS DE COOKIES</b>
      </p>
      <p className="item-content">
        Según cual sea la entidad que gestione la Web desde donde se envíen las
        cookies y traten los datos que se obtengan, se pueden distinguir las
        siguientes:
      </p>
      <p className="sub-item-content">
        i. Cookies propias: Son aquellas que se envían al dispositivo de acceso
        a Internet del Usuario desde la web gestionada por el propio titular del
        sitio y desde el que se presta el servicio solicitado por el Usuario de
        Internet.
      </p>
      <p className="sub-item-content">
        ii. Cookies de tercero: Son aquellas que se envían al dispositivo de
        acceso a Internet del Usuario desde la Web pero que no es gestionado por
        el titular del sitio, sino por otra entidad que trata los datos
        obtenidos mediante las cookies.
      </p>
      <p className="item-content">
        Según el plazo de tiempo que permanecen activadas en el dispositivo de
        acceso a Internet del Usuario, se pueden distinguir las siguientes:
      </p>
      <p className="sub-item-content">
        i. Cookies de sesión: Son un tipo de cookies diseñadas para recoger y
        almacenar datos mientras el Usuario accede a la Web. Se suelen utilizar
        para almacenar información que sólo interesa conservar para la
        prestación del servicio solicitado por el Usuario en una sola ocasión
        (la sesión que está utilizando en ese momento).
      </p>
      <p className="sub-item-content">
        ii. Cookies persistentes: Son un tipo de cookies en que los datos siguen
        almacenados en el dispositivo de acceso a Internet del Usuario y pueden
        ser accedidas y tratadas durante un periodo definido por el responsable
        de la cookie, y que puede ir de unos minutos a varios años.
      </p>
      <p className="item-content">
        Según la finalidad para la que se traten los datos obtenidos mediante
        las cookies, se pueden distinguir las siguientes:
      </p>
      <p className="sub-item-content">
        i. Cookies técnicas: Son aquellas imprescindibles y estrictamente
        necesarias para el correcto funcionamiento de la Web y la utilización de
        las diferentes opciones y servicios que ofrece. Son de este tipo, entre
        otras, las que sirven para el mantenimiento de la sesión, la gestión del
        tiempo de respuesta, rendimiento o validación de opciones, utilizar
        elementos de seguridad o compartir contenido con redes sociales.
      </p>
      <p className="sub-item-content">
        ii. Cookies de personalización: Son aquellas que permiten al Usuario
        acceder al servicio con algunas características de carácter general
        predefinidas en función de una serie de criterios en el dispositivo del
        Usuario, como, por ejemplo; el idioma, el tipo de navegador que utiliza
        para acceder al servicio, la configuración regional desde donde accede
        al servicio, etc.
      </p>
      <p className="sub-item-content">
        iii. Cookies de Geo-localización: Son utilizadas para averiguar en qué
        país se encuentra cuando se solicita un servicio. Esta cookie es
        totalmente anónima, y sólo se utiliza para ayudar a orientar el
        contenido a su ubicación.
      </p>
      <p className="sub-item-content">
        iv. Cookies de análisis: Son aquellas que permiten al responsable de las
        mismas, el seguimiento y análisis del comportamiento de los Usuarios en
        la Web. La información recogida mediante este tipo de cookies se utiliza
        en la medición de la actividad de la Web y para la elaboración de
        perfiles de navegación de los Usuarios, con el fin de introducir mejoras
        en función del análisis de los datos de uso que hacen los Usuarios del
        servicio.
      </p>
      <p className="sub-item-content">
        v. Cookies publicitarias: Son aquellas que permiten la gestión, de la
        forma más eficaz posible, de los espacios publicitarios que, en su caso,
        el titular haya incluido en la Web desde el que presta el servicio
        solicitado sobre la base de criterios como el contenido editado o la
        frecuencia en la que se muestran los anuncios.
      </p>
      <p className="sub-item-content">
        vi. Cookies de publicidad comportamental: Son aquellas que permiten la
        gestión, de la forma más eficaz posible, los espacios publicitarios que,
        en su caso, el titular haya incluido en la Web desde el que presta el
        servicio solicitado. Estas cookies almacenan información del
        comportamiento de los Usuarios obtenida por la observación continuada de
        sus hábitos de navegación, lo que permite desarrollar un perfil
        específico para mostrar publicidad en función de éste.
      </p>
      <p className="sub-item-content">
        vii. Cookies de Registro: Son aquellas que se generan una vez que el
        Usuario se ha registrado o posteriormente ha abierto su sesión, y se
        utilizan para identificarle en los servicios de forma que, si cierra un
        servicio, el navegador o el ordenador, y en otro momento u otro día
        vuelve a entrar en dicho servicio, seguirá identificado, facilitando así
        su navegación sin tener que volver a identificarse. Esta funcionalidad
        se puede suprimir si el Usuario pulsa la funcionalidad “cerrar sesión”,
        de forma que esta Cookie se elimina y la próxima vez que entre en el
        servicio el Usuario tendrá que iniciar sesión para estar identificado.
      </p>
      <p>
        <b>6. ¿QUÉ TIPO DE COOKIES UTILIZA ESTE SITIO WEB?</b>
      </p>
      <p className="item-content">
        Las cookies siguientes no identifican personalmente a los Usuarios, sólo
        proporcionan información estadística anónima sobre la navegación en esta
        Web. Sin embargo, puede deshabilitarlas directamente configurando su
        navegador.
      </p>
      <p className="item-content">
        <b>Inca Tops</b>
      </p>
      <table>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Tipo</th>
          <th scope="col">Permanencia</th>
          <th scope="col">Finalidad</th>
        </tr>

        <tr>
          <td>csrftoken</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies técnicas</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies técnicas</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies técnicas</td>
        </tr>
      </table>
      <br />
      <p className="item-content">
        <b>Pacomarca</b>
      </p>
      <table>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Tipo</th>
          <th scope="col">Permanencia</th>
          <th scope="col">Finalidad</th>
        </tr>

        <tr>
          <td>csrftoken</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies técnicas</td>
        </tr>
        <tr>
          <td>__utma</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>__utmc</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>__utmz</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>__utmt</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>__utmtb</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
      </table>
      <br />
      <p className="item-content">
        <b>Amano Yarns</b>
      </p>
      <table>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Tipo</th>
          <th scope="col">Permanencia</th>
          <th scope="col">Finalidad</th>
        </tr>

        <tr>
          <td>qtrans_front_language</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies técnicas</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
        <tr>
          <td>_gat</td>
          <td>Cookies de tercero</td>
          <td>Cookies persistentes</td>
          <td>Cookies de análisis</td>
        </tr>
      </table>
      <br />
      <img src={CookiesImage} style={{ width: '100%' }} alt="Cookies Policy" />
      <br />
      <br />
      <p>
        <b>7. GESTIÓN DE LAS COOKIES</b>
      </p>
      <p className="item-content">
        Proporcionando esta política, exponemos a nuestros Usuarios el
        compromiso adquirido sobre el uso de cookies, dándole acceso a
        información para que usted pueda comprender qué tipo de cookies
        utilizamos y por qué lo hacemos. Con esto, pretendemos proporcionarle
        transparencia en cuanto a los datos tratados acerca de la navegación
        realizada desde su equipo en la Web.
      </p>
      <p className="item-content">
        Si acepta nuestras cookies, nos permite la mejora de la Web de INCA TOPS
        para ofrecerle un acceso óptimo y darle un servicio más eficaz y
        personalizado.
      </p>
      <p className="item-content">
        Además, usted puede configurar su navegador para establecer que sólo los
        sitios Web de confianza o las páginas por las que está navegando en este
        momento puedan gestionar cookies lo que le permite seleccionar sus
        preferencias.
      </p>
      <p className="item-content">
        Si usted como Usuario decide no autorizar el tratamiento indicándonos su
        disconformidad, sólo usaríamos las cookies técnicas, puesto que son
        imprescindibles para la navegación por nuestra Web, por ello no
        almacenaríamos ninguna cookie. En el caso de seguir navegando por
        nuestra Web sin denegar su autorización implicaría que usted acepta su
        uso.
      </p>
      <p className="item-content">
        Tenga en cuenta que, si rechaza o borra las cookies de navegación por la
        Web, no podremos mantener sus preferencias, algunas características de
        las páginas no estarán operativas, no podremos ofrecerle servicios
        personalizados y cada vez que vaya a navegar por nuestra Web tendremos
        que solicitarle de nuevo su autorización para el uso de cookies.
      </p>
      <p className="item-content">
        Si, aun así, decide modificar la configuración de su acceso a la página
        Web, debe saber que es posible eliminar las cookies o impedir que se
        registre esta información en su equipo en cualquier momento mediante la
        modificación de los parámetros de configuración de su navegador.
      </p>
      <p className="item-content">
        Para bloquear o deshabilitar las cookies el Usuario deberá activar la
        configuración del navegador, de manera que se rechace la instalación de
        todas las cookies o de algunas de ellas. En la práctica, la mayoría de
        los navegadores permiten advertir la presencia de cookies o rechazarlas
        automáticamente. Si son rechazadas se podrá seguir usando nuestra Web,
        aunque el uso de algunos de sus servicios podrá ser limitado y por tanto
        la experiencia del Usuario puede ser menos satisfactoria.
      </p>
      <p className="item-content">
        Los accesos a la configuración de cookies de los principales navegadores
        son los siguientes:
      </p>
      <p className="sub-item-content">
        <b>
          i. Internet Explorer: Herramientas -&gt; Opciones de Internet -&gt;
          Privacidad -&gt; Configuración.
        </b>
      </p>
      <p className="item-content">
        Para más información, puede consultar el soporte de Microsoft o la Ayuda
        del navegador. En cada navegador la operativa que ha de seguirse es
        diferente, pero la función de &#34;Ayuda&#34; de la Web de cada
        navegador le mostrará cómo hacerlo, como sucede por ejemplo para
        Internet Explorer:
        windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=&#34;ie-10&#34;
      </p>
      <p className="sub-item-content">
        <b>
          i. Safari: Preferencias -&gt; Seguridad. Para más información, puede
          consultar el soporte de Apple o la Ayuda del navegador en{' '}
        </b>
        <a href="https://www.apple.com/es/privacy/use-of-cookies">
          <b>www.apple.com/es/privacy/use-of-cookies</b>
        </a>
      </p>
      <p className="sub-item-content">
        <b>
          ii. Firefox: Herramientas -&gt; Opciones -&gt; Privacidad -&gt;
          Historial -&gt; Configuración Personalizada. Para más información,
          puede consultar el soporte de Mozilla o la Ayuda del navegador en{' '}
        </b>
        <a href="https://support.mozilla.org/es/kb/Borrar%20cookies">
          <b>support.mozilla.org/es/kb/Borrar%20cookies</b>
        </a>
      </p>
      <p className="sub-item-content">
        <b>
          iii. Chrome: Configuración -&gt; Mostrar opciones avanzadas -&gt;
          Privacidad -&gt; Configuración de contenido. Para más información,
          puede consultar el soporte de Google o la Aydua del navegador en{' '}
        </b>
        <a href="https://support.google.com/chrome/answer/95647?hl=%22es%22">
          <b>support.google.com/chrome/answer/95647?hl=&#34;es&#34;</b>
        </a>
      </p>
      <p>
        <b>8. OTRAS CUESTIONES</b>
      </p>
      <p className="item-content">
        Para realizar cualquier tipo de consulta respecto a esta política puede
        dirigirse a la siguiente dirección de correo electrónico:{' '}
        <a href="mailto:derechosarco@incatops.com">derechosarco@incatops.com</a>
      </p>
      <p>
        <b>9. EJERCICIO DE DERECHOS</b>
      </p>
      <p className="item-content">
        Los Usuarios que hayan facilitado sus datos personales a INCA TOPS
        pueden dirigirse a ésta, con el fin de poder ejercer sus derechos de
        información, de acceso, de actualización, inclusión, rectificación y
        supresión, de impedir el suministro de sus datos personales, de
        oposición al tratamiento o de tratamiento objetivo de los datos, en los
        términos recogidos en la legislación peruana vigente
      </p>
      <p className="item-content">
        Para poder ejercer estos derechos, pueden dirigirse por cualquier medio
        que permita acreditar el envío y la recepción de la solicitud a la
        dirección: Avenida Miguel Forga 348 Z.I. Parque Industrial, Arequipa, o
        a la siguiente dirección de correo electrónico:
        derechosarco@incatops.com con la referencia “Protección de Datos
        Personales”, especificando sus datos, acreditando su identidad y los
        motivos de su solicitud.
      </p>
      <p className="item-content">
        Aunado a ello, los titulares de datos personales podrán presentar una
        reclamación ante la Autoridad Nacional de Protección de Datos
        Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia
        y Derechos Humanos: Calle Scipión Llona N° 350, Miraflores, Lima, Perú,
        de considerar que no han sido atendidos en el ejercicio de sus derechos.
      </p>
      <p>
        <b>10. VIGENCIA Y MODIFICACIÓN DE LA PRESENTE POLÍTICA DE COOKIES</b>
      </p>
      <p className="item-content">
        La Política de Cookies Web de INCA TOPS ha sido actualizada el mes de
        enero de 2021.
      </p>
      <p className="item-content">
        INCA TOPS puede modificar esta Política de Cookies en función de
        exigencias legislativas, reglamentarias, jurisprudenciales o con la
        finalidad de adaptar dicha política a las instrucciones dictadas por la
        Dirección de Protección de Datos Personales, así como por criterios
        propios institucionales.
      </p>
      <p className="item-content">
        Por ello, y dado que dicha política puede ser actualizada
        periódicamente, sugerimos a nuestros Usuarios que la revisen de forma
        regular y que la encontrarán en la Web{' '}
        <a href="https://incatops.com/es/cookiespolicy">
          www.incatops.com/es/cookiespolicy
        </a>
      </p>
    </div>
  );
};

CookiesEs.propTypes = {};
export default CookiesEs;
