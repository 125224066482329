// Import core modules
import React, { useEffect } from 'react';
import { Row, Col, Typography, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useMutation } from 'react-query';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Components

// Import Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';

// Import Styles
import './index.scss';

const { Title } = Typography;

function ActivationPage() {
  const screens = useBreakpoint();
  const { uuid, token } = useParams();

  const { t } = useTranslation('common');

  const quotation = useMutation(
    quotationData => Api.post('/api/users/activation/', quotationData),
    {
      onError: err => {
        HandleErroMessage(err);
        notification.error({
          message: t('Something went wrong'),
          description: t('An error has occurred, please contact us'),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
      },
      onSuccess: () => {},
    }
  );

  useEffect(() => {
    const body = { uuid, token };
    quotation.mutate(body);
  }, []);

  return (
    <Row className="ActivationPage" justify="center" align="middle">
      <Col>
        <Title
          className="uppercase light title-container"
          level={UnderBreakpoint(screens, 'sm') ? 4 : 3}
        >
          {t('Your account has been activated')}
          <Link to="/" className="boton">
            {t('Return home')}
          </Link>
        </Title>
      </Col>
    </Row>
  );
}

export default ActivationPage;
