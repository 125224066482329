// Import core modules
import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { LoadingOutlined } from '@ant-design/icons';

// Import Components

// Import Images

// Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Styles
import './index.scss';

function LoadingIndicator(props) {
  const { small } = props;
  const screens = useBreakpoint();

  return (
    <div className="LoadingIndicator">
      <LoadingOutlined />
    </div>
  );
}

LoadingIndicator.propTypes = {
  small: PropTypes.bool,
};
LoadingIndicator.defaultProps = {
  small: false,
};

export default LoadingIndicator;
