// Import core modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Modal, Tooltip } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// Import Components
import ColorDetail from '~/Components/ColorDetail';

// Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Images

// Import Styles
import './index.scss';

const MAX_COLORS = 12;

function ColorList(props) {
  const { colors, productData, inStock, tonality } = props;

  const [showAll, setShowAll] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [activeColor, setActiveColor] = useState(colors[0]);

  const screens = useBreakpoint();

  var activeColors = colors;

  if (inStock === true) {
    activeColors = activeColors.filter(color => color.amount > 0);
  }
  if (tonality) {
    activeColors = activeColors.filter(color => color.tonality == tonality);
  }

  return (
    <Row className="ColorList">
      <Col xs={22} lg={23}>
        <Row className="Color list">
          {activeColors
            .slice(
              0,
              !showAll
                ? UnderBreakpoint(screens, 'md')
                  ? 4
                  : UnderBreakpoint(screens, 'xxl')
                    ? 6
                    : MAX_COLORS
                : colors.lenght
            )
            .map(color => (

              <Col
                key={color.id}
                className="color-container"
                xs={6}
                lg={4}
                xl={4}
                xxl={4}
              >
                <Button
                  type="primary"
                  style={{
                    background: `url(${color.photo_thumbnail}) no-repeat`,
                  }}
                  className="btnDetail"
                  shape="circle"
                  onClick={() => {
                    setActiveColor(color);
                    setShowDetail(true);
                  }}
                />

                <p>{color.code}</p>
                {color.tag && (
                  <Tooltip placement="right" color={"#FFF"} title={() => <p style={{
                    color: "#000"
                  }}>{color.tag}</p>}  >
                    <div className='tag' />
                  </Tooltip>

                )}
              </Col>
            ))}
        </Row>
      </Col>
      <Col className="arrow-container" xs={2} lg={1}>
        {showAll ? (
          <Button
            type="text"
            onClick={() => setShowAll(false)}
            className="btnContainer"
            icon={<CaretUpOutlined />}
          />
        ) : (
          <Button
            type="text"
            onClick={() => setShowAll(true)}
            className="btnContainer"
            icon={<CaretDownOutlined />}
          />
        )}
      </Col>
      <Modal
        title={activeColor.code}
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        footer={null}
        centered={true}
      >
        <ColorDetail
          colorID={activeColor.id}
          productData={{
            colorThumbnail: activeColor.photo_thumbnail,
            ...productData,
          }}
          onClose={() => setShowDetail(false)}
          inPopover
        />
      </Modal>
    </Row>
  );
}

ColorList.propTypes = {
  colors: PropTypes.array.isRequired,
  productData: PropTypes.shape({
    name: PropTypes.string,
    minimumOrder: PropTypes.number,
    image: PropTypes.string,
  }).isRequired,
};

export default ColorList;
