// Import Styles
import './App.scss';

import { Layout, Typography } from 'antd';
// Import core modules
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import ActivationPage from '~/Pages/ActivationPage';
import AppFooter from '~/Components/AppFooter';
// Import Components/Pages
import AppHeader from '~/Components/AppHeader';
// Import Images
import BlackSquares from '~/Resources/images/logo.ico';
import CookiesPolicy from '~/Pages/CookiesPolicy';
import DetailsPage from '~/Pages/DetailsPage';
import { Helmet } from 'react-helmet';
import Home from '~/Pages/Home';
import PaymentPage from '~/Pages/PaymentPage';
import PrivacyPolicy from '~/Pages/PrivacyPolicy';
import QuotationPage from '~/Pages/QuotationPage';
import SearchPage from '~/Pages/SearchPage';
import { useTranslation } from 'react-i18next';

// Import  Utils

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

function App(props) {
  const [activeProduct, setActiveProduct] = useState('');
  const [setShowBanner] = useState(true);
  const [search, setSearch] = useState('');
  const [typeSearch, setTypeSearch] = useState('');
  const location = useLocation();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (location.pathname === '/quotation') {
      setActiveProduct('QUOTATION');
    }
    if (location.pathname === '/') {
      setActiveProduct('');
    }
    if (location?.state?.back) {
      setShowBanner(false);
    }
  }, [location]);

  const onSearch = value => {
    setSearch(value);
  };

  const getCategoryName = () => {
    switch (typeSearch) {
      case 'H':
        return t('HAND KNITTING');
      case 'I':
        return t('INDUSTRIAL KNITTING');
      default:
        return t('');
    }
  };

  return (
    <div className="App">
      <Helmet>
        <link rel="icon" type="image/png" href={BlackSquares} sizes="16x16" />
        <title>Stock Service - Inca Tops</title>
      </Helmet>
      <Layout className="layout">
      <Header className="MainHeader">
          <AppHeader
            setActiveProduct={setActiveProduct}
            setShowBanner={setShowBanner}
            activeProduct={activeProduct}
            onSearch={onSearch}
            productCategory={getCategoryName()}
            typeSearch={typeSearch}
            setTypeSearch={setTypeSearch}
          />
        </Header>    
        <Content className="MainConent">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path={`/:type/search`} exact>
              <SearchPage search={search} />
            </Route>

            <Route path="/activation/:uuid/:token">
              <ActivationPage />
            </Route>
            <Route path="/:type/details/:id">
              <DetailsPage
                setActiveProduct={setActiveProduct}
                typeSearch={typeSearch}
                setTypeSearch={setTypeSearch}
              />
            </Route>
            <Route path="/quotation">
              <QuotationPage />
            </Route>
            <Route path="/payment/:quotationId">
              <PaymentPage />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/cookies-policy">
              <CookiesPolicy />
            </Route>
          </Switch>
        </Content>
        <Footer className="MainFooter">
          <AppFooter />
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
