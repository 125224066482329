// Import core modules
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Breadcrumb,
  Select,
  Button,
  Popover,
  Modal,
  notification,
  Input,
  Space,
} from 'antd';
import Icon, {
  ShoppingCartOutlined,
  CaretDownOutlined,
  DownloadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import jwt from 'jsonwebtoken';

// Context
import { CartContext, CART_ACTIONS } from '~/Contexts/CartProvider';
import { UserContext, USER_ACTIONS } from '~/Contexts/UserProvider';

// Import Components
import Cart from '~/Components/Cart';
import Login from '~/Components/Login';
import Logout from '~/Components/Logout';
import ForgotPassword from '~/Components/ForgotPassword';
import Register from '~/Components/Register';
import ResetPassword from '~/Components/ResetPassword';

// Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Images
import { ReactComponent as Logo } from '~/Resources/images/LogoInkaTops.svg';

// Import Styles
import './index.scss';

const { Option } = Select;
const { Search } = Input;

const BrandIcon = props => <Icon component={Logo} {...props} />;

function AppHeader(props) {
  const [, dispatch] = useContext(UserContext);
  const [, dispatchCart] = useContext(CartContext);
  const location = useLocation();
  const history = useHistory();
  const { activeProduct, onSearch, setActiveProduct } = props;
  const [show, setShow] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForget, setShowForget] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [activeUser, setActiveUser] = useState(false);
  const [showWarningLogout, setShowWarningLogout] = useState(false);
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [state] = useContext(CartContext);
  const [stateUser] = useContext(UserContext);
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('common');
  const [typeSearch, setTypeSearch] = useState('');

  const [canDownloadExcel, setCanDownloadExcel] = useState(false);
  const [tokenData, setTokenData] = useState('');
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (
      location.pathname.includes('hand_knitting') ||
      location.pathname.includes('industrial_knitting')
    ) {
      setShowInputSearch(true);
    } else {
      setShowInputSearch(false);
    }

    const pathCategoryType = location.pathname.split('/');
    if (pathCategoryType[1]) {
      setTypeSearch(pathCategoryType[1]);
    } else {
      setTypeSearch('');
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('/resetPassword')) {
      setShowReset(true);
      const URLToken = location.pathname.split('token=')[1];
      setResetToken(URLToken);
    }
    setCanDownloadExcel(stateUser.canDownloadExcel);
  }, []);

  useEffect(() => {
    const token =
      stateUser.userToken.userToken !== undefined
        ? stateUser.userToken.userToken
        : stateUser.userToken;
    
    if (token !== '') {
      jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY, function(
        err,
        decoded
        
      ) {        
        if (err) {
          notification.error({
            message: t('Sign in'),
            description: t('Your session was expired'),
            placement: 'bottomRight',
            className: 'error-notification',
          });
          dispatch({
            type: USER_ACTIONS.REMOVE_TOKEN,
          });
          dispatch({
            type: USER_ACTIONS.REMOVE_EXCEL_DOWNLOAD,
          });
          setActiveUser(false);
          setCanDownloadExcel(false);
        }
        if (decoded !== undefined) {
          setActiveUser(true);
          setCanDownloadExcel(stateUser.canDownloadExcel);
          setTokenData(decoded.data);          
        }
      });
    } else if (location.pathname.includes('quotation')) {
      setShowLog(true);
    } else {
      setActiveUser(false);
    }
    setCanDownloadExcel(stateUser.canDownloadExcel);
  }, [stateUser, location.pathname]);

  const logoutWarning = () => {
    dispatch({
      type: USER_ACTIONS.REMOVE_TOKEN,
    });
    dispatch({
      type: USER_ACTIONS.REMOVE_EXCEL_DOWNLOAD,
    });
    dispatchCart({
      type: CART_ACTIONS.CLEAR,
    });
    notification.success({
      message: t('Logout successful'),
      placement: 'bottomRight',
      className: 'success-notification',
    });
    setShowWarningLogout(false);
  };

  const logout = () => {
    if (state.cartItems.length > 0) {
      setShowWarningLogout(true);
    } else {
      dispatch({
        type: USER_ACTIONS.REMOVE_TOKEN,
      });
      dispatch({
        type: USER_ACTIONS.REMOVE_EXCEL_DOWNLOAD,
      });
      notification.success({
        message: t('Logout successful'),
        placement: 'bottomRight',
        className: 'success-notification',
      });
    }
  };

  const redirectHome = () => {
    setShowLog(false);
    if (location.pathname.includes('quotation')) {
      history.push('/');
    }
    history.push('/');
  };

  const donwloadExcel = () => {    
    const url = process.env.REACT_APP_SERVER_LINK + '/api/products/product_export/?token=' + tokenData + '&lang=' + language;
    window.open(url);
  }

  return (
    <Row justify="space-between" className="AppHeader" wrap>
      <Space align="center" direction="horizontal" wrap>
        <Col span="none">
          <Link to="/" onClick={() => onSearch('')}>
            <BrandIcon className="logo" />
          </Link>
          {!UnderBreakpoint(screens, 'md') && (
            <>
              {location.pathname !== '/' ? (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="" onClick={() => onSearch('')}>
                      STOCK SERVICE
                    </Link>
                  </Breadcrumb.Item>
                  {location.pathname !== '/quotation' && (
                    <Breadcrumb.Item onClick={() => setActiveProduct('')}>
                      <Link to={`/${typeSearch}/search`}>
                        {t(typeSearch.toUpperCase())}
                      </Link>
                    </Breadcrumb.Item>
                  )}
                  <Breadcrumb.Item>{t(activeProduct)}</Breadcrumb.Item>
                </Breadcrumb>
              ) : (
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="">STOCK SERVICE</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
            </>
          )}
        </Col>
      </Space>
      <Col className="options-container" span="none">
        <Space
          className={
            location.pathname !== `/${typeSearch}/search`
              ? 'search-container'
              : 'search-container-responsive'
          }
          direction={UnderBreakpoint(screens, 'md') ? 'vertical' : 'horizontal'}
        >
          {showInputSearch && (
            <Col>
              <Search
                placeholder={t('Search')}
                enterButton
                className="btnSearch"
                onSearch={value => onSearch(value)}
              />
            </Col>
          )}

          <Col>
            <Space direction="horizontal">
              {canDownloadExcel === true ? (
                <Button
                  className=""
                  icon={<DownloadOutlined />}
                  onClick={() => donwloadExcel()}
                >
                  {t('Updated')}
                </Button>
              ) : (
                <></>
              )}
              <Button
                className={!activeUser ? 'userBtn' : 'userBtn rotate'}
                size={UnderBreakpoint(screens, 'md') ? 'middle' : 'large'}
                icon={!activeUser ? <UserOutlined /> : <DownloadOutlined />}
                onClick={() => (!activeUser ? setShowLog(!show) : logout())}
              />

              {UnderBreakpoint(screens, 'md') ? (
                <Button
                  className="cart btnHeader"
                  size={UnderBreakpoint(screens, 'md') ? 'middle' : 'large'}
                  icon={<ShoppingCartOutlined />}
                  onClick={() => setShow(!show)}
                >
                  <span>{state.cartItems.length}</span>
                </Button>
              ) : (
                <Popover
                  color="#EAEAEA"
                  placement="bottomRight"
                  trigger="click"
                  autoAdjustOverflow
                  content={<Cart showPopover={v => setShow(v)} />}
                  overlayClassName="Cart-overlay"
                  visible={show}
                  onVisibleChange={visible => setShow(visible)}
                >
                  <Button
                    className="cart btnHeader"
                    size={UnderBreakpoint(screens, 'md') ? 'middle' : 'large'}
                    icon={<ShoppingCartOutlined />}
                    onClick={() => setShow(!show)}
                  >
                    <span>{state.cartItems.length}</span>
                  </Button>
                </Popover>
              )}

              <Select
                className="language btnHeader"
                size={UnderBreakpoint(screens, 'md') ? 'middle' : 'large'}
                defaultValue="EN"
                dropdownClassName="select-language"
                suffixIcon={<CaretDownOutlined />}
                onChange={value => {                                                
                  i18n.changeLanguage(value);
                  value === 'en' ? setLanguage('en') : setLanguage('es')           
                }}                
              >                                
                <Option className="select-language-text" value="en">
                  EN
                </Option>
                <Option className="select-language-text" value="es">
                  ES
                </Option>
              </Select>
            </Space>
          </Col>
        </Space>
      </Col>
      <Modal
        visible={show && UnderBreakpoint(screens, 'md')}
        onCancel={() => setShow(false)}
        footer={null}
      >
        <Cart showPopover={v => setShow(v)} />
      </Modal>
      <Modal
        visible={showLog}
        onCancel={redirectHome}
        footer={null}
        width={700}
      >
        <Login
          showPopover={v => setShowLog(v)}
          setShowForget={setShowForget}
          setShowRegister={setShowRegister}
        />
      </Modal>
      <Modal
        visible={showRegister}
        onCancel={() => setShowRegister(false)}
        footer={null}
        width={800}
      >
        <Register showPopover={v => setShowRegister(v)} />
      </Modal>
      <Modal
        visible={showWarningLogout}
        onOk={() => logoutWarning()}
        onCancel={() => setShowWarningLogout(false)}
        width={700}
        okText={t('Yes')}
        cancelText="No"
      >
        <Logout />
      </Modal>
      <Modal
        visible={showForget}
        onCancel={() => setShowForget(false)}
        footer={null}
        width={700}
      >
        <ForgotPassword showPopover={v => setShowForget(v)} />
      </Modal>
      <Modal
        visible={showReset}
        onCancel={() => setShowReset(false)}
        footer={null}
        width={700}
      >
        <ResetPassword
          showPopover={v => setShowReset(v)}
          resetToken={resetToken}
        />
      </Modal>
    </Row>
  );
}

AppHeader.propTypes = {
  activeProduct: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  restarState: PropTypes.func.isRequired,
};

export default AppHeader;
