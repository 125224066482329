// Import core modules
import React from 'react';

// Import Locals
import TermsConditions from '~/Components/TermsConditions';
import './index.scss';

function PrivacyPolicy() {
  return <TermsConditions typeModal="terms" />;
}

export default PrivacyPolicy;
