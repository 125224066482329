import React from 'react';

import IncaTopsLogoTerms from '~/Resources/images/IncaTopsLogoTerms.png';

const TermsEs = () => {
  return (
    <div className="terms">
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '30px' }}>POLÍTICA DE PRIVACIDAD</p>
        <p style={{ fontSize: '25px' }}>
          <b>INCA TOPS S.A.</b>
        </p>
      </div>
      <p style={{ float: 'right' }}>Arequipa, octubre 2020</p>
      <p style={{ marginTop: '5rem' }}>
        <b>1. ALCANCE</b>
      </p>
      <p className="item-content">
        Esta “Política de Privacidad en Protección de Datos Personales” se
        aplicará a todos los bancos de datos y/o archivos que contengan datos
        personales que sean objeto de tratamiento por parte de INCA TOPS S.A.
        (en adelante INCA TOPS), empresa que cuenta con la siguiente
        información:
      </p>
      <p className="sub-item-content">
        <b>Nombre:</b> INCA TOPS S.A.
      </p>
      <p className="sub-item-content">
        <b>RUC N°:</b> 20100199743
      </p>
      <p className="sub-item-content">
        <b>Domicilio: </b>Avenida Miguel Forga 348 Z.I. Parque Industrial,
        Arequipa
      </p>
      <p>
        <b>2. INTRODUCCIÓN</b>
      </p>
      <p className="item-content">
        En INCA TOPS aseguramos la máxima reserva y protección de los datos
        personales de titularidad de nuestros clientes, colaboradores,
        proveedores, etc.
      </p>
      <p className="item-content">
        Este documento describe la “Política de Privacidad en Protección de
        Datos Personales” que regula el tratamiento de los datos personales, que
        en INCA TOPS realizamos en el desarrollo de nuestras actividades
        comerciales.
      </p>
      <p>
        <b>3. OBJETIVO Y FINALIDAD</b>
      </p>
      <p className="item-content">
        En INCA TOPS somos conscientes de la elevada consideración que tiene la
        privacidad de nuestros clientes y colaboradores y de todas aquellas
        personas que se interesan por nuestros productos y servicios. Siendo
        consecuentes con esta consideración, tenemos el compromiso de respetar
        su privacidad y proteger la confidencialidad de su información privada y
        datos personales. Por ello, el objetivo de esta política de general de
        privacidad es dar a conocer la manera en que se recogen, se tratan y se
        protegen los datos personales a los que accedemos en el ejercicio de
        nuestras actividades comerciales.
      </p>
      <p className="item-content">
        La información personal privada no incluye datos que están disponibles a
        través de fuentes accesibles al público. Se entiende por tales, los
        medios de comunicación electrónica, óptica y de otra tecnología
        concebidos para facilitar información al público y abiertos a la
        consulta general, las guías telefónicas, los diarios y revistas, los
        medios de comunicación social, las listas profesionales, los repertorios
        de jurisprudencia anonimizados, los Registros Públicos administrados por
        la Superintendencia Nacional de Registros Públicos así como todo otro
        registro o banco de datos calificado como público conforme a ley.
      </p>
      <p>
        <b>
          4. TRATAMIENTO DE DATOS PERSONALES EN EL PROCESO DE RECLUTAMIENTO DE
          PERSONAL
        </b>
      </p>
      <p className="item-content">
        <b>A. FINALIDAD DE TRATAMIENTO EN EL RECLUTAMIENTO DE PERSONAL</b>
      </p>
      <p className="sub-item-content">
        INCA TOPS recopilará los datos personales de los interesados en un
        puesto de trabajo con la finalidad de:
      </p>
      <p className="sub-item-content">
        i. Gestionar el proceso de selección para contratación de personal al
        interior de la empresa.
      </p>
      <p className="sub-item-content">
        ii. Almacenar información de candidatos para contratación de personal al
        interior de INCA TOPS en función a los requerimientos de la empresa.
      </p>
      <p className="sub-item-content">
        iii. Registro de los datos personales en el banco de datos Postulantes
        laborales de titularidad de INCA TOPS.
      </p>
      <p className="item-content">
        <b>B. DATOS PERSONALES DE CARÁCTER OBLIGATORIO</b>
      </p>
      <p className="sub-item-content">
        Para llevar a cabo las finalidades descritas en la presente política de
        privacidad, es obligatorio nos proporcione los siguientes datos
        personales: nombres y apellidos, número de DNI, número de carné de
        extranjería, domicilio, imagen, teléfono fijo y móvil, correo
        electrónico, fecha y lugar de nacimiento, sexo, profesión, edad, datos
        académicos y datos de persona de contacto.
      </p>
      <p className="item-content">
        <b>C. CONSECUENCIAS DE PROPORCIONAR LOS DATOS O NEGATIVA DE HACERLO</b>
      </p>
      <p className="sub-item-content">
        De no proporcionar los datos obligatorios no podrá participar del
        proceso de reclutamiento de personal.
      </p>
      <p className="item-content">
        <b>D. PLAZO DE CONSERVACIÓN</b>
      </p>
      <p className="sub-item-content">
        Sus datos personales serán tratados de forma temporal durante el
        desarrollo del proceso para la contratación de personal y serán
        almacenados en el banco de datos de Postulantes Laborales por el plazo
        máximo de un (1) año.
      </p>
      <p>
        <b>5. TRATAMIENTO DE DATOS PERSONALES EN EL ENVÍO DE PUBLICIDAD</b>
      </p>
      <p className="item-content">
        <b>A. FINALIDAD DE TRATAMIENTO PARA EL ENVÍO DE PUBLICIDAD</b>
      </p>
      <p className="sub-item-content">
        INCA TOPS recopilará los datos personales de los interesados en recibir
        publicidad con la finalidad de:
      </p>
      <p className="sub-item-content">
        <b>
          1. Enviar publicidad de INCA TOPS y/o de las empresas que forman parte
          del mismo grupo y/o de sus socios comerciales, públicos o privados,
          para lo cual podrán utilizar, entre otros medios, los centros de
          llamadas (call centers), sistemas de llamado telefónico, envío de
          mensajes de texto a celular o de correos electrónicos masivos y/o
          personalizados, envío de revistas y/o catálogos y/o cualquier otro
          medio de comunicación similar existente o por existir.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          2. Registrar sus datos personales en el banco de datos Publicidad, el
          cual es de titularidad de INCA TOPS.
        </b>
      </p>
      <p className="item-content">
        <b>B. DATOS PERSONALES DE CARÁCTER OBLIGATORIO Y FACULTATIVO</b>
      </p>
      <p className="sub-item-content">
        Para llevar a cabo las finalidades descritas en la presente política de
        privacidad, es obligatorio nos proporcione los siguientes datos
        personales: Nombres y apellidos, correo electrónico, empresa en la que
        trabaja, país de residencia.
      </p>
      <p className="sub-item-content">
        Son datos facultativos los siguientes: domicilio, teléfono fijo y móvil.
      </p>
      <p className="item-content">
        <b>C. CONSECUENCIAS DE PROPORCIONAR LOS DATOS O NEGATIVA DE HACERLO</b>
      </p>
      <p className="sub-item-content">
        Sólo se le enviará publicidad por los medios proporcionados. Sin
        embargo, sus nombres y apellidos, correo electrónico, empresa en la que
        trabaja y país de residencia son estrictamente necesarios para el
        tratamiento de sus datos personales con estos fines.
      </p>
      <p className="item-content">
        <b>D. PLAZO DE CONSERVACIÓN</b>
      </p>
      <p className="sub-item-content">
        Sus datos personales serán tratados mientras sean necesarios para
        cumplir con las finalidades anteriormente descritas, o hasta que el
        titular manifieste su intención de cesar el tratamiento a través de los
        canales puestos a su disposición para el ejercicio de sus derechos ARCO.
      </p>
      <p>
        <b>
          6. TRATAMIENTO DE DATOS PERSONALES CON FINES DE COTIZACIÓN Y RELACIÓN
          COMERCIAL
        </b>
      </p>
      <p className="item-content">
        <b>FINALIDAD DE TRATAMIENTO PARA LA COTIZACIÓN Y RELACIÓN COMERCIAL</b>
      </p>
      <p className="item-content">
        INCA TOPS recopilará los datos personales de los clientes con la
        finalidad de:
      </p>
      <p className="item-content">
        i. Realizar la cotización de un producto o servicio de INCA TOPS
      </p>
      <p className="item-content">
        ii. Ejecutar la relación contractual que surja entre el cliente e INCA
        TOPS.
      </p>
      <p className="item-content">
        iii. Usar, brindar y/o transferir esta información a los proveedores de
        servicios tecnológicos que contraten con INCA TOPS, así como a
        autoridades y terceros autorizados por ley.
      </p>
      <p className="item-content">
        iv. Cumplir con fines estadísticos e históricos para INCA TOPS.
      </p>
      <p className="item-content">
        v. Registrar sus datos personales en el Banco de Datos Clientes de
        titularidad de INCA TOPS
      </p>
      <p className="item-content">
        vi. Evaluar cualquier solicitud que efectúe y/o pudiere efectuar, así
        como la realización de encuestas de satisfacción;
      </p>
      <p className="item-content">
        vii. Fines informativos, ya sean propios de INCA TOPS y/o de las
        empresas que forman parte del mismo grupo y/o de sus socios comerciales,
        públicos o privados, para lo cual podrán utilizar, entre otros medios,
        los centros de llamadas (call centers), sistemas de llamado telefónico,
        envío de mensajes de texto a celular o de correos electrónicos masivos
        y/o personalizados, envío de revistas y/o catálogos y/o cualquier otro
        medio de comunicación similar existente o por existir.
      </p>
      <p className="item-content">
        viii. Usar, brindar y/o transferir esta información para dar
        cumplimiento a las obligaciones y/o requerimientos que se generen en
        virtud de las normas vigentes en el ordenamiento jurídico peruano,
        incluyendo, las del sistema de prevención de lavado de activos y
        financiamiento del terrorismo y normas prudenciales.
      </p>
      <p className="item-content">
        <b>B. DATOS PERSONALES DE CARÁCTER OBLIGATORIO Y FACULTATIVO</b>
      </p>
      <p className="sub-item-content">
        Para llevar a cabo las finalidades descritas en la presente política de
        privacidad, es obligatorio nos proporcione los siguientes datos
        personales: nombres y apellidos, teléfono, correo electrónico, firma,
        domicilio, RUC, número de cuenta bancaria.
      </p>
      <p className="item-content">
        <b>C. CONSECUENCIAS DE PROPORCIONAR LOS DATOS O NEGATIVA DE HACERLO</b>
      </p>
      <p className="sub-item-content">
        De no proporcionar los datos obligatorios no podrá realizarse la
        cotización solicitada ni podrá adquirir alguno de nuestros productos o
        servicios.
      </p>
      <p className="item-content">
        <b>D. PLAZO DE CONSERVACIÓN</b>
      </p>
      <p className="sub-item-content">
        Sus datos personales serán tratados mientras sean necesarios para
        cumplir con las finalidades anteriormente descritas, o hasta por un
        plazo máximo de cinco (05) años contados a partir de la adquisición de
        nuestro producto o servicio, salvo por exigencias legales que pudieren
        exceder dicho plazo.
      </p>
      <p>
        <b>
          7. TRATAMIENTO DE DATOS PERSONALES CON FINES DE COTIZACIÓN Y RELACIÓN
          COMERCIAL DE CLIENTES QUE RESIDAN
        </b>
      </p>
      <p className="item-content">
        <b>EN LA UNIÓN EUROPEA</b>
      </p>
      <p className="item-content">
        <b>
          A. FINALIDAD DE TRATAMIENTO PARA LA COTIZACIÓN Y RELACIÓN COMERCIAL
        </b>
      </p>
      <p className="sub-item-content">
        INCA TOPS recopilará los datos personales de los clientes con la
        finalidad de:
      </p>
      <p className="sub-item-content">
        <b>i. Realizar la cotización de un producto o servicio de INCA TOPS</b>
      </p>
      <p className="sub-item-content">
        <b>
          ii. Ejecutar la relación contractual que surja entre el cliente e INCA
          TOPS.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          iii. Usar, brindar y/o transferir esta información a los proveedores
          de servicios tecnológicos que contraten con INCATOPS, así como a
          autoridades y terceros autorizados por ley.
        </b>
      </p>
      <p className="sub-item-content">
        <b>iv. Cumplir con fines estadísticos e históricos para INCA TOPS.</b>
      </p>
      <p className="sub-item-content">
        <b>
          v. Registrar sus datos personales en el Banco de Datos Clientes de
          titularidad de INCA TOPS.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          vi. Evaluar cualquier solicitud que efectúe y/o pudiere efectuar, así
          como la realización de encuestas de satisfacción.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          vii. Fines informativos, ya sean propios de INCA TOPS y/o de las
          empresas que forman parte del mismo grupo y/o de sus socios
          comerciales, públicos o privados, para lo cual podrán utilizar, entre
          otros medios, los centros de llamadas (call centers), sistemas de
          llamado telefónico, envío de mensajes de texto a celular o de correos
          electrónicos masivos y/o personalizados, envío de revistas y/o
          catálogos y/o cualquier otro medio de comunicación similar existente o
          por existir.
        </b>
      </p>
      <p className="sub-item-content">
        <b>
          viii. Usar, brindar y/o transferir esta información para dar
          cumplimiento a las obligaciones y/o requerimientos que se generen en
          virtud de las normas vigentes en el ordenamiento jurídico peruano,
          incluyendo, las del sistema de prevención de lavado de activos y
          financiamiento del terrorismo y normas prudenciales.
        </b>
      </p>
      <p className="item-content">
        <b>B. BASE O FUNDAMENTACIÓN JURÍDICA DE TRATAMIENTO</b>
      </p>
      <p className="sub-item-content">
        El tratamiento de datos personales de los clientes se encuentre
        sustentado en el art. 6.1b, el cual establece que todo tratamiento es
        lícito cuando el tratamiento es necesario para la ejecución de un
        contrato en el que el interesado es parte o para la aplicación a
        petición de este de medidas precontractuales.
      </p>
      <p className="sub-item-content">
        Asimismo, le recordamos que usted cuenta con su derecho habilitado para
        acceder a la autoridad de control de la Unión Europea en materia de
        protección de datos personales a fin de hacer valer los derechos que el
        marco normativo le asiste.
      </p>
      <p className="sub-item-content">
        Por otro lado, se le informa la existencia de decisiones automatizadas
        en cuanto a la elaboración de perfiles con la información que nos
        proporciona, decisiones que se enmarcan dentro de las finalidades
        señaladas.
      </p>
      <p className="item-content">
        <b>C. DATOS PERSONALES DE CARÁCTER OBLIGATORIO Y FACULTATIVO</b>
      </p>
      <p className="sub-item-content">
        Para llevar a cabo las finalidades descritas en la presente política de
        privacidad, es obligatorio nos proporcione los siguientes datos
        personales: nombres y apellidos, teléfono, correo electrónico, firma,
        domicilio, RUC, número de cuenta bancaria.
      </p>
      <p className="item-content">
        <b>D. CONSECUENCIAS DE PROPORCIONAR LOS DATOS O NEGATIVA DE HACERLO</b>
      </p>
      <p className="sub-item-content">
        De no proporcionar los datos obligatorios no podrá realizarse la
        cotización solicitada ni podrá adquirir alguno de nuestros productos o
        servicios.
      </p>
      <p className="item-content">
        <b>E. PLAZO DE CONSERVACIÓN</b>
      </p>
      <p className="sub-item-content">
        Sus datos personales serán tratados mientras sean necesarios para
        cumplir con las finalidades anteriormente descritas, o hasta por un
        plazo máximo de cinco (05) años contados a partir de la adquisición de
        nuestro producto o servicio, salvo por exigencias legales que pudieren
        exceder dicho plazo.
      </p>
      <p>
        <b>
          8. TRATAMIENTO DE DATOS PERSONALES PARA LA ATENCIÓN DE QUEJAS Y
          RECLAMOS
        </b>
      </p>
      <p className="item-content">
        <b>
          A. FINALIDAD DE TRATAMIENTO DE DATOS LA ATENCIÓN DE QUEJAS Y RECLAMOS
        </b>
      </p>
      <p className="sub-item-content">
        INCA TOPS recopilará los datos personales de las personas que presenten
        algún reclamo, queja y sugerencia con la finalidad de:
      </p>
      <p className="sub-item-content">
        Dar respuesta a la queja o reclamo que pueda presentar alguna persona,
        así como tomar nota de las sugerencias que se hagan.
      </p>
      <p className="sub-item-content">
        Registrar sus datos personales en el banco de datos Quejas y Reclamos,
        el cual es de titularidad de INCA TOPS.
      </p>
      <p className="sub-item-content">
        Dar cumplimiento a lo establecido por el Código de Protección al
        Consumidor, el Reglamento del Libro de Reclamaciones del Código de
        Protección al Consumidor y demás normativa aplicable.
      </p>
      <p className="item-content">
        <b>B. DATOS PERSONALES DE CARÁCTER OBLIGATORIO</b>
      </p>
      <p className="sub-item-content">
        Para llevar a cabo las finalidades descritas en la presente política de
        privacidad, es obligatorio nos proporcione los siguientes datos
        personales: Nombres y apellidos, correo electrónico, domicilio, DNI,
        teléfono celular, edad.
      </p>
      <p className="item-content">
        <b>C. CONSECUENCIAS DE PROPORCIONAR LOS DATOS O NEGATIVA DE HACERLO</b>
      </p>
      <p className="sub-item-content">
        En caso que el consumidor no consigne como mínimo su nombre, DNI,
        domicilio o correo electrónico, fecha del reclamo o queja y el detalle
        de los mismos, estos se consideraran como no presentados.
      </p>
      <p className="item-content">
        <b>D. PLAZO DE CONSERVACIÓN</b>
      </p>
      <p className="sub-item-content">
        Sus datos personales serán tratados mientras sean necesarios para
        cumplir con las finalidades anteriormente descritas y por el plazo
        máximo de dos (02) años contados desde la fecha de presentación de la
        queja o reclamo.
      </p>
      <p>
        <b>9. CONFIDENCIALIDAD DE LOS DATOS PERSONALES</b>
      </p>
      <p className="item-content">
        Los datos personales facilitados serán tratados con total
        confidencialidad. INCA TOPS se compromete a guardar secreto profesional
        indefinidamente respecto de los mismos y garantiza el deber de
        guardarlos adoptando todas las medidas de seguridad necesarias.
      </p>
      <p>
        <b>10. TRANSFERENCIA, ENCARGO Y FLUJO TRANSFRONTERIZO</b>
      </p>
      <p className="item-content">
        INCA TOPS podrá transferir sus datos personales dentro del territorio
        nacional o al exterior, por cualquier medio, a sus subsidiarias,
        personas jurídicas vinculadas, a sus proveedores de servicios, sean
        personas naturales o jurídicas únicamente para el cumplimiento de las
        finales desarrolladas en relación a cada uno de los tratamientos
        desarrollados. La relación de tales personas podrá ser consultada en la
        página
      </p>
      <p className="item-content">
        <a href="https://incatops.com/es/supplieragreements">
          www.incatops.com/es/supplieragreements
        </a>
      </p>
      <p>
        <b>11. EJERCICIO DE DERECHOS</b>
      </p>
      <p className="item-content">
        Los Usuarios que hayan facilitado sus datos personales a INCA TOPS
        pueden dirigirse a ésta, con el fin de poder ejercer sus derechos de
        información, de acceso, de actualización, inclusión, rectificación y
        supresión, de impedir el suministro de sus datos personales, de
        oposición al tratamiento o de tratamiento objetivo de los datos, en los
        términos recogidos en la legislación peruana vigente.
      </p>
      <p className="item-content">
        Para poder ejercer estos derechos, pueden dirigirse por cualquier medio
        que permita acreditar el envío y la recepción de la solicitud a la
        dirección: Avenida Miguel Forga 348 Z.I. Parque Industrial, Arequipa, o
        escribir al mail derechosarco@incatops.com con la referencia “Protección
        de Datos Personales”, especificando sus datos, acreditando su identidad
        y los motivos de su solicitud.
      </p>
      <p className="item-content">
        Aunado a ello, los titulares de datos personales podrán presentar una
        reclamación ante la Autoridad Nacional de Protección de Datos
        Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia
        y Derechos Humanos: Calle Scipión Llona N° 350, Miraflores, Lima, Perú,
        de considerar que no han sido atendidos en el ejercicio de sus derechos.
      </p>
      <p>
        <b>12. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</b>
      </p>
      <p className="item-content">
        INCA TOPS se reserva el derecho de efectuar en cualquier momento
        modificaciones, adecuaciones o actualizaciones a la presente política de
        privacidad, para la atención de nuevas disposiciones legales,
        reglamentarias, jurisprudenciales o políticas internas adoptadas.
      </p>
    </div>
  );
};

TermsEs.propTypes = {};
export default TermsEs;
