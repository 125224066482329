import React, { useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button, Form, Input, Select, Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// Inport Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';
// Import Styles
import './index.scss';

const { Title, Text } = Typography;

const Register = ({ showPopover }) => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('common');
  const { Option } = Select;
  const [countries, setCountries] = useState([]);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleError, setVisibleError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { isLoading, error, data: countryData } = useQuery(
    'fetchCountries',
    () => Api.options('/api/users/clients/')
  );

  useEffect(() => {
    if (!isLoading) {
      setCountries(countryData.data.actions.POST.country.choices);
    }
  }, [isLoading]);
  useEffect(() => {
    if (error) {
      HandleErroMessage(error);
    }
  }, [error]);

  const quotationMutation = useMutation(
    quotationData => Api.post('/api/users/clients/', quotationData),
    {
      onError: err => {
        if (
          err.response.data.user.email[0] ===
          'Ya existe usuario con este email.'
        ) {
          setEmailError(true);
        } else {
          HandleErroMessage(err);
          showPopover(false);
          setVisibleError(true);
        }
      },
      onSuccess: () => {
        setVisibleSuccess(true);
        showPopover(false);
      },
    }
  );

  const onSubmitForm = async values => {
    const { city, country, tax_id, ...user } = values;
    const newUser = {
      tax_id,
      city,
      country,
      user,
      preferred_language: i18n.language.toUpperCase(),
    };
    quotationMutation.mutate(newUser);
  };

  return (
    <>
      <div className="Register">
        <Row justify="center">
          <Col className="title-container">
            <Title
              className="f500"
              level={UnderBreakpoint(screens, 'md') ? 4 : 3}
            >
              {t('Completa tus datos para continuar con tu registro')}
            </Title>
          </Col>

          <Col span={24}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              layout="inline"
              validateTrigger="onSubmit"
              size="large"
              onFinish={onSubmitForm}
            >
              <Row justify="end" className="form-container">
                <Col span={24}>
                  <Form.Item
                    name="name"
                    label={t('Full Name / Company Name')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('Full Name / Company Name')} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label={t('Email')}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: 'email',
                      },
                    ]}
                  >
                    <Input placeholder={t('Email')} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="tax_id"
                    label={t('Tax ID')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('Tax ID')} />
                  </Form.Item>
                </Col>
                <Col span={24} className="country">
                  <Form.Item
                    name="country"
                    label={t('Country')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={t('Country')}
                      className="width-100"
                      loading={isLoading}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Children.toArray(
                        countries.map(country => (
                          <Option value={country.value}>
                            {country.display_name}
                          </Option>
                        ))
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="city"
                    label={t('City')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder={t('City')} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="password"
                    label={t('Password')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.Password placeholder={t('Password')} />
                  </Form.Item>
                </Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={quotationMutation.isLoading}
                >
                  {t('Register')}
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <Modal
        visible={visibleSuccess}
        onCancel={() => setVisibleSuccess(false)}
        footer={null}
        closable={false}
        width={700}
      >
        <div className="Register">
          <Row justify="center">
            <Col className="title-container">
              <Title
                className="f500"
                level={UnderBreakpoint(screens, 'md') ? 4 : 3}
              >
                {t('Registration is complete')}
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col className="message-container">
              <Text level={UnderBreakpoint(screens, 'md') ? 4 : 3}>
                {t(
                  'An email has been sent to confirm and activate your Inca Tops account, please check your email inbox.'
                )}
              </Text>
            </Col>
            <Button type="primary" onClick={() => setVisibleSuccess(false)}>
              {t('Accept')}
            </Button>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={visibleError}
        onCancel={() => setVisibleError(false)}
        footer={null}
        closable={false}
        width={700}
      >
        <div className="Register">
          <Row justify="center">
            <Col className="title-container">
              <Title
                className="f500"
                level={UnderBreakpoint(screens, 'md') ? 4 : 3}
              >
                {t('Something went wrong')}
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col className="message-container">
              <Text level={UnderBreakpoint(screens, 'md') ? 4 : 3}>
                {t(
                  'An error has occurred. Please check that the information provided is correct or try again later.'
                )}
              </Text>
            </Col>
            <Button type="primary" onClick={() => setVisibleError(false)}>
              {t('Accept')}
            </Button>
          </Row>
        </div>
      </Modal>
      <Modal
        visible={emailError}
        onCancel={() => setEmailError(false)}
        footer={null}
        closable={false}
        width={700}
      >
        <div className="Register">
          <Row justify="center">
            <Col className="title-container">
              <Title
                className="f500"
                level={UnderBreakpoint(screens, 'md') ? 4 : 3}
              >
                {t('Something went wrong')}
              </Title>
            </Col>
          </Row>
          <Row justify="center" className="email-error">
            <Col className="message-container">
              <Text level={UnderBreakpoint(screens, 'md') ? 4 : 3}>
                {t('There is already an account with this email')}
              </Text>
            </Col>
            <Button type="primary" onClick={() => setEmailError(false)}>
              {t('Accept')}
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

Register.propTypes = {
  showPopover: PropTypes.func.isRequired,
};

export default Register;
