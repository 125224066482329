import axios from 'axios';
import jwt from 'jsonwebtoken';

const stateUser = JSON.parse(localStorage.getItem('user'));
let currentToken = '';
let headers = {};

if (stateUser) {
  currentToken = jwt.decode(stateUser).data;
  headers = {
    Authorization: `Token ${currentToken}`,
  };
}

export default axios.create({
  baseURL: process.env.REACT_APP_SERVER_LINK,
  headers,
});
