// Import core modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space, Carousel, Modal, Tag } from 'antd';
import Magnifier from 'react-magnifier';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// Import Components
import ColorList from '~/Components/ColorList';
// Utils
// Import Images
import defaultImg from '~/Resources/images/defecto.jpg';
// Import Styles
import './index.scss';

const { Title, Text } = Typography;

function SearchColorDetail(props) {
  const { product } = props;
  const { inStock } = props;
  const { tonality } = props;
  const [show, setShow] = useState(false);
  const [tyPath, setTyPath] = useState('');
  const { t } = useTranslation('common');
  const location = useLocation();



  useEffect(() => {
    const pathCategoryType = location.pathname.split('/');
    setTyPath(pathCategoryType[1]);
  }, [location]);

  return (
    <div className="SearchColorDetail">
      <Row>
        <Col span={14}>
          <Row align="middle">
            <Col span={24}>
              <Link to={`/${tyPath}/details/${product.id}`}>
                <Title className="title" level={5}>
                  {product.name}
                </Title>
              </Link>
              <Space className="characteristics left-text" direction="vertical">
                <Text className="details">
                  {`${t('Composition')} ${product.composition}`}
                </Text>
                <Text className="details">
                  {`${t('Count NM')} ${product.metric_number?.name}`}
                </Text>
              </Space>

              {product.tag && (
                <Tag color="#2db7f5" style={{
                  marginTop: '10px',
                }}>{product.tag}</Tag>
              )}
            </Col>
          </Row>
        </Col>

        <Col span={10}>
          <Row gutter={24} justify="end" className="image-container">
            <Col>
              <div role="button" onClick={() => setShow(true)}>
                <img
                  className="main"
                  src={
                    tyPath === 'hand_knitting'
                      ? product.braid_thumbnail || defaultImg
                      : product.cone_thumbnail || defaultImg
                  }
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="detail-colors" span={24}>
          <Text className="colors-text f500 left-text">{t('Colors')}:</Text>
          <ColorList
            colors={product.colour_set}
            productData={{
              name: product.name,
              image:
                tyPath === 'hand_knitting'
                  ? product.braid_thumbnail || defaultImg
                  : product.cone_thumbnail || defaultImg,
              minimumOrder: product.minimum_order,
              category: product.category,
            }}
            inStock={inStock}
            tonality={tonality}
          />
        </Col>
      </Row>
      <Modal visible={show} onCancel={e => setShow(false)} footer={null}>
        <Carousel arrows className="carousel-image-container">
          {product.cone_thumbnail && (
            <div className="carousel-image">
              <Magnifier
                src={product.cone_thumbnail}
                width={280}
                mgShowOverflow={false}
                mgShape="square"
              />
            </div>
          )}
          {product.braid_thumbnail && (
            <div className="carousel-image">
              <Magnifier
                src={product.braid_thumbnail}
                width={280}
                mgShowOverflow={false}
                mgShape="square"
              />
            </div>
          )}
          {product.swatch_thumbnail && (
            <div className="carousel-image">
              <Magnifier
                src={product.swatch_thumbnail}
                width={280}
                mgShowOverflow={false}
                mgShape="square"
              />
            </div>
          )}
        </Carousel>
      </Modal>
    </div>
  );
}

SearchColorDetail.propTypes = {
  product: PropTypes.object.isRequired,
};

export default SearchColorDetail;
