import React from 'react';

import './styles.scss';

const ImageModal = image => {
  return (
    <img src={image.image} className="modal-image" alt="Modal certification" />
  );
};

ImageModal.propTypes = {};
export default ImageModal;
