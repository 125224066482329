import React from 'react';
import { Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

// Import Styles
import './index.scss';

const Logout = () => {
  const { Text } = Typography;
  const { t } = useTranslation('common');
  return (
    <>
      <Col className="col-body">
        <Row className="row-body">
          <Text className="title-text">{t('WARNING')}</Text>
        </Row>
        <Row>
          <Text className="body-text">
            {t('The items on the shopping cart will be lost, logout anyway?')}
          </Text>
        </Row>
      </Col>
    </>
  );
};

export default Logout;
