import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Button, Form, Input, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

// Import Utils
import { UnderBreakpoint, HandleErroMessage } from '~/Utils/Functions';
import Api from '~/Utils/Api';

// Import Styles
import './index.scss';

const { Text } = Typography;

const ForgotPassword = ({ showPopover }) => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { t } = useTranslation('common');

  const quotationMutation = useMutation(
    quotationData => Api.post('/api/users/password-reset/', quotationData),
    {
      onError: err => {
        HandleErroMessage(err);
        showPopover(true);
        notification.error({
          message: t('Something went wrong'),
          description: t('An error has occurred, please try again'),
          placement: 'bottomLeft',
          className: 'error-notification',
        });
      },
      onSuccess: () => {
        notification.success({
          message: t(
            'We send you the instructions to reset your password to your email'
          ),
          description: t('Please check your mailbox'),
          placement: 'bottomLeft',
          className: 'success-notification',
        });
        showPopover(false);
      },
    }
  );

  const onSubmitForm = async values => {
    const body = {
      email: values.email,
    };
    quotationMutation.mutate(body);
  };

  return (
    <div className="Forgot">
      <Col span={24} order={UnderBreakpoint(screens, 'md') ? 0 : 1}>
        <Row align="middle">
          {!UnderBreakpoint(screens, 'md') && (
            <Col className="title-container optima" span={24}>
              <Text className="f500" level={4}>
                {t(
                  'Enter the e-mail address you used to register to receive an e-mail with instructions on how to reset your password'
                )}
              </Text>
            </Col>
          )}
          <Col className="" span={24}>
            <Form
              form={form}
              name="basic"
              initialValues={{ remember: true }}
              validateTrigger="onSubmit"
              size="large"
              onFinish={onSubmitForm}
            >
              <Row justify="end" className="form-container">
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label={t('Email')}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: 'email',
                        message: t('Enter a valid email'),
                      },
                    ]}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Col>

                <Col
                  className="send-button forgot"
                  span={24}
                  order={UnderBreakpoint(screens, 'md') ? 2 : 2}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={quotationMutation.isLoading}
                  >
                    {t('Password recovery')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

ForgotPassword.propTypes = {
  showPopover: PropTypes.func.isRequired,
};

export default ForgotPassword;
