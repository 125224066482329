// Import core modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Modal, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// Import Components

// Import Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Images

// Import Styles
import './index.scss';
import ColorDetail from '../ColorDetail';

function DetailColorList(props) {
  const { colors, productData, onColorClick } = props;
  const [showDetail, setShowDetail] = useState(false);
  const [activeColor, setActiveColor] = useState(colors[0]);
  const screens = useBreakpoint();

  useEffect(() => {
    if (colors.length) {
      setActiveColor(colors[0]);
    }
  }, [colors]);


  return (
    <Row className="DetailColorList">
      <Col span={24}>
        <Row>
          {colors.map(color => (
            <Col key={color.id} className="color-container" style={{ position: "relative" }} span={6}>
              <div
                className={
                  activeColor.id === color.id && !UnderBreakpoint(screens, 'md')
                    ? 'color-wraper-focus'
                    : 'color-wraper'
                }
              >
                {UnderBreakpoint(screens, 'md') ? (
                  <Button
                    type="primary"
                    style={{
                      background: `url(${color.photo_thumbnail}) no-repeat`,
                    }}
                    className="btnDetail"
                    shape="circle"
                    onClick={() => {
                      setActiveColor(color);
                      setShowDetail(true);
                    }}

                  />
                ) : (
                  <Button
                    type="primary"
                    style={{
                      background: `url(${color.photo_thumbnail}) no-repeat`,
                    }}
                    className="btnDetail"
                    shape="circle"
                    onClick={() => {
                      setActiveColor(color);
                      onColorClick(color);
                    }}
                  />
                )}

                <p>{color.code}</p>
              </div>
              {color.tag && (
                <Tooltip placement="right" color={"#FFF"} title={() => <p style={{
                  color: "#000"
                }}>{color.tag}</p>}  >
                  <div className='tag' />
                </Tooltip>

              )}
            </Col>
          ))}
        </Row>
      </Col>
      <Modal
        title={activeColor.code}
        visible={showDetail}
        onCancel={e => setShowDetail(false)}
        footer={null}
      >
        <ColorDetail
          colorID={activeColor.id}
          productData={{
            colorThumbnail: activeColor.photo_thumbnail,
            ...productData,
          }}
          onClose={() => setShowDetail(false)}
          inPopover
        />
      </Modal>
    </Row>
  );
}

DetailColorList.propTypes = {
  colors: PropTypes.array.isRequired,
  onColorClick: PropTypes.func.isRequired,
  productData: PropTypes.shape({
    productID: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
    minimumOrder: PropTypes.number,
  }).isRequired,
};

export default DetailColorList;
