// Import core modules
import React, { useEffect, useState, Children } from 'react';
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Radio,
  Input,
  Space,
  Collapse,
  Checkbox,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useQuery, useQueries } from 'react-query';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useLocation } from 'react-router-dom';
// Import Components
import Border from '~/Components/Border';
import SearchColorDetail from '~/Components/SearchColorDetail';
import LoadingIndicator from '~/Components/LoadingIndicator';

// Import Utils
import { UnderBreakpoint, AddFilter } from '~/Utils/Functions';
import Api from '~/Utils/Api';

// Import Styles
import './index.scss';

const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const subCategories = [
  {
    id: 'E',
    name_en: 'Essentials (Regular Yarns)',
    name_es: 'Essentials (Hilos Regulares) ',
    banner: false,
  },
  {
    id: 'V',
    name_en: 'Essentilas Vives (Fancy Yarns)',
    name_es: 'Essentials Vibes (Hilos Fantasia) ',
    banner: true,
  },
];

function SearchPage({ search }) {
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation('common');
  const [typeSearch, setTypeSearch] = useState('');
  const [tyPath, setTyPath] = useState('');
  const [showAttributes, setShowAttributes] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [atributesFilter, setAtributesFilter] = useState([]);
  const [atributesFilterName, setAtributesFilterName] = useState([]);
  const [showWeights, setShowWeights] = useState(false);
  const [weights, setWeights] = useState([]);
  const [weightFilter, setWeightFilter] = useState('');
  const [weightFilterName, setWeightFilterName] = useState('');
  const [showSuggestedNeedles, setShowSuggestedNeedles] = useState(false);
  const [suggestedNeedles, setSuggestedNeedles] = useState([]);
  const [suggestedNeedlesFilter, setSuggestedNeedlesFilter] = useState('');
  const [suggestedNeedlesFilterName, setSuggestedNeedlesFilterName] = useState(
    ''
  );
  const [showMetricNumbers, setShowMetricNumbers] = useState(false);
  const [metricNumbers, setMetricNumbers] = useState([]);
  const [metricNumbersFilter, setMetricNumbersFilter] = useState('');
  const [metricNumbersFilterName, setMetricNumbersFilterName] = useState('');

  const [showSubCategory, setShowSubCategory] = useState(false);
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryFilterName, setSubCategoryFilterName] = useState('');
  const [ordering, setOrdering] = useState('');
  const [minimumOrderLte, setMinimumOrderLte] = useState('');
  const [minimumOrderGte, setMinimumOrderGte] = useState('');
  const [filters, setFilters] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const location = useLocation();  

  const [showStock, setShowStock] = useState(false);
  const [stockFilter, setStockFilter] = useState(false);
  const [stockFilterName, setStockFilterName] = useState('');


  const [showTonalities, setShowTonalities] = useState(false);
  const [tonalities, setTonalities] = useState([]);
  const [tonalitiesFilter, setTonalitiesFilter] = useState('');
  const [tonalitiesFilterName, setTonalitiesFilterName] = useState('');

  const [showProducts, setShowProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsFilter, setProductsFilter] = useState('');
  const [productsFilterName, setProductsFilterName] = useState('');



  
  const getTonalities = async () => {
    const fetchTonalities = await Api.get('/api/products/tonality/')

    return fetchTonalities;
  }

  const getProducts = async () => {
    
    const pathCategoryType = window.location.pathname.split('/');   
    var type = "";
    if (pathCategoryType[1]) {
      type = getCategoryType(pathCategoryType[1]);      
    }    

    const url = "/api/products/filter_products/"
    const result = await Api.get(url, {
      params: {        
        category__in: [type, 'B'].toString(),
      },}); 
    return result;
  }

  const getAttributes = async () => {
    const fetchAttributes = await Api.get('/api/products/attributes/');
    return fetchAttributes;
  };

  const getCategoryType = type => {
    switch (type) {
      case 'hand_knitting':
        return 'H';
      case 'industrial_knitting':
        return 'I';
      default:
        return '';
    }
  };

  useEffect(() => {
    const pathCategoryType = location.pathname.split('/');
    if (pathCategoryType[1]) {
      const type = getCategoryType(pathCategoryType[1]);
      setTypeSearch(type);
      setTyPath(pathCategoryType[1]);
    }
  }, [location]);

  const getWeights = async () => {
    const fetchWeights = await Api.get('/api/products/weights/');
    return fetchWeights;
  };

  const getSuggestedNeedles = async () => {
    const fetchSuggestedNeedles = await Api.get(
      '/api/products/suggested_needles/'
    );
    return fetchSuggestedNeedles;
  };

  const getMetricNumbers = async () => {
    const fetchMetricNumbers = await Api.get('/api/products/metric_numbers/');
    return fetchMetricNumbers;
  };

  useQueries([
    {
      queryKey: ['attributes'],
      queryFn: () => getAttributes(),
      onSuccess: res => {
        setAttributes(res.data);
      },
    },
    {
      queryKey: ['weights'],
      queryFn: () => getWeights(),
      onSuccess: res => {
        setWeights(res.data);
      },
    },
    {
      queryKey: ['suggestedNeedles'],
      queryFn: () => getSuggestedNeedles(),
      onSuccess: res => {
        setSuggestedNeedles(res.data);
      },
    },
    {
      queryKey: ['metricNumbers'],
      queryFn: () => getMetricNumbers(),
      onSuccess: res => {
        setMetricNumbers(res.data);
      },
    },
    {
      queryKey: ['tonalities'],
      queryFn: () => getTonalities(),
      onSuccess: res => {
        setTonalities(res.data);
      },
    },
    {
      queryKey: ['products'],
      queryFn: () => getProducts(),
      onSuccess: res => {
        var data = [];
        res.data.map((product) =>{
          data.push(product.name);
        });
        data = [...new Set(data)];
        setProducts(data);
      },
    },
  ]);

  const handleAttributes = toNumber => {
    const currentIndex = atributesFilter.indexOf(toNumber.target.value);
    const newChecked = [...atributesFilter];
    if (currentIndex === -1) {
      newChecked.push(toNumber.target.value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setAtributesFilter(newChecked);
    
  };

  const getProductsFilters = async () => {
    const url = `/api/products/products/search/`;
    const result = await Api.get(url, {
      params: {
        search,
        ordering,
        category__in: [typeSearch, 'B'].toString(),
        minimum_order__lte: minimumOrderLte,
        minimum_order__gte: minimumOrderGte,
        [typeSearch === 'H'
          ? 'suggested_needle_hand_knitting'
          : 'suggested_needle_industrial_knitting']: suggestedNeedlesFilter.toString(),

        [typeSearch === 'H'
          ? 'weight_hand_knitting'
          : 'weight_industrial_knitting']: weightFilter.toString(),
        attributes__id__in: atributesFilter.toString(),
        metric_number: metricNumbersFilter,
        subcategory: subCategory,
        stock: stockFilter,
        tonality: tonalitiesFilter,
        product: productsFilter,
      },
    });
    return result;
  };

  const { isLoading } = useQuery(
    [
      search,
      ordering,
      typeSearch,
      minimumOrderLte,
      minimumOrderGte,
      atributesFilter,
      suggestedNeedlesFilter,
      metricNumbersFilter,
      weightFilter,
      subCategory,
      stockFilter,
      tonalitiesFilter,
      productsFilter,
    ],
    () => getProductsFilters(),
    {
      onSuccess: res => {
        setAllProducts(res?.data?.results);
      },
    }
  );

  const getNameById = () => {
    const getNameAtributes = atributesFilter.map(item => {
      const filter = attributes.find(ev => item === ev.id);
      return filter.name;
    });
    setAtributesFilterName(getNameAtributes);

    const filterSubCategory = subCategories.find(ev => subCategory === ev.id);
    if (filterSubCategory) {
      setSubCategoryFilterName(filterSubCategory.name_en);
    }

    const filterWeights = weights.find(ev => weightFilter === ev.id);
    if (filterWeights) {
      setWeightFilterName(filterWeights.name);
    }

    const filterSuggestedNeed = suggestedNeedles.find(
      ev => suggestedNeedlesFilter === ev.id
    );
    if (filterSuggestedNeed) {
      setSuggestedNeedlesFilterName(filterSuggestedNeed.name);
    }
    const filterMetricNumber = metricNumbers.find(
      ev => metricNumbersFilter === ev.id
    );
    if (filterMetricNumber) {
      setMetricNumbersFilterName(filterMetricNumber.name);
    }
    if(stockFilter){
      setStockFilterName('In stock');
    }

    const filterTonalities = tonalities.find(ev => tonalitiesFilter === ev.id);
    if (filterTonalities) {
      setTonalitiesFilterName(filterTonalities.name);
    }

    const filterProducts = products.find(ev => productsFilter === ev.id);
    if (filterProducts) {
      setProductsFilterName(filterProducts.name);
    }
  };

  useEffect(() => {
    getNameById();
  }, [
    atributesFilter,
    weightFilter,
    suggestedNeedlesFilter,
    subCategory,
    metricNumbersFilter,
    stockFilter,
    tonalitiesFilter,
    productsFilter,
  ]);

  useEffect(() => {    
    setFilters(
      AddFilter(
        subCategoryFilterName,
        ordering,
        minimumOrderLte,
        minimumOrderGte,
        atributesFilterName,
        weightFilterName,
        suggestedNeedlesFilterName,
        metricNumbersFilterName,
        stockFilterName,
        tonalitiesFilterName,
        productsFilterName,
      )
    );
    if (search === undefined) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }    
  }, [
    subCategoryFilterName,
    search,
    ordering,
    minimumOrderLte,
    minimumOrderGte,
    i18n.language,
    atributesFilterName,
    weightFilterName,
    suggestedNeedlesFilterName,
    metricNumbersFilterName,
    stockFilterName,
    tonalitiesFilterName,
    productsFilterName,
  ]);

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value: filters,
    onChange: newFilters => {
      setFilters(newFilters);


      const tonality = newFilters.find(filter => filter.includes('tonality:'));
      if (!tonality) {
        setTonalitiesFilterName('');
        setTonalitiesFilter('');
      }

      const product = newFilters.find(filter => filter.includes('product:'));
      if (!product) {
        setProductsFilterName('');
        setProductsFilter('');
      }

      const weightFilter = newFilters.find(filter =>
        filter.includes('weight:')
      );
      if (!weightFilter) {
        setWeightFilter('');
        setWeightFilterName('');
      }
      const comp = newFilters.find(filter => filter.includes('comp:'));
      if (!comp) {
        setAtributesFilterName([]);
        setAtributesFilter([]);
      }

      const sugg = newFilters.find(filter => filter.includes('sugg:'));
      if (!sugg) {
        setSuggestedNeedlesFilter('');
        setSuggestedNeedlesFilterName('');
      }
      const metric = newFilters.find(filter => filter.includes('metric:'));
      if (!metric) {
        setMetricNumbersFilter('');
        setMetricNumbersFilterName('');
      }
      const subcat = newFilters.find(filter => filter.includes('subCat:'));
      if (!subcat) {
        setSubCategoryFilterName('');
        setSubCategory('');
      }

      const stockFilterValues = newFilters.find(filter =>
        filter.includes('stock:')
      );
      if (!stockFilterValues) {
        setStockFilter(false);
        setStockFilterName('');
      }else{
        setStockFilter(true);
        setStockFilterName('In stock');
      }
    },
    maxTagCount: 10,
  };

  const clearFilters = () => {
    setOrdering('');
    setMinimumOrderLte('');
    setMinimumOrderGte('');
    setFilters([]);
    setAtributesFilterName([]);
    setAtributesFilter([]);
    setWeightFilter('');
    setWeightFilterName('');
    setMetricNumbersFilter('');
    setMetricNumbersFilterName('');
    setSuggestedNeedlesFilter('');
    setSuggestedNeedlesFilterName('');
    setSubCategory('');
    setSubCategoryFilterName('');
    setStockFilterName('');
    setStockFilter(false);
    setTonalitiesFilterName('');
    setTonalitiesFilter('');
    setProductsFilterName('');
    setProductsFilter('');
  }; 
  let timer = null;
  return (
    <>
      <div className="SearchPage">
        <Row gutter={20}>
          <Col className="left-col-ba" md={24}>
            <Row className="order-col">
              <Col md={12}>
                <div className="title-container">
                  <Title className="uppercase light" level={4}>
                    {showSearch
                      ? `${t('Showing')} ${allProducts.length} ${t(
                          'results of'
                        )} ${allProducts.length}`
                      : `${t('Showing')} ${allProducts.length} ${t(
                          'results of'
                        )} ${allProducts.length}`}
                  </Title>
                </div>
              </Col>
              <Col md={12}>
                <Select
                  defaultValue=""
                  onChange={val => setOrdering(val)}
                  value={ordering}
                  className="order-select"
                >
                  <Option key="orderBy" value="">
                    {t('Order by')}
                  </Option>
                  <Option key="name" value="name">
                    {t('Alphabetical asc')}
                  </Option>
                  <Option key="-name" value="-name">
                    {t('Alphabetical desc')}
                  </Option>
                  <Option key="minimum_order" value="minimum_order">
                    {t('Minimum order asc')}
                  </Option>
                  <Option key="-minimum_order" value="-minimum_order">
                    {t('Minimum order desc')}
                  </Option>
                </Select>
              </Col>
            </Row>
          </Col>

          <Col md={8} className="gutter-row border-right">
            <Row className="filters-container-b">
              <Collapse
                className="filters-collapse"
                collapsible={!UnderBreakpoint(screens, 'md') ? 'disabled' : ''}
                // #TO DO : refactor this any other way, using a UnderBreakPoint.
                defaultActiveKey={window.innerWidth > 768 ? ['1'] : ''}
                ghost
              >
                <Panel
                  showArrow={UnderBreakpoint(screens, 'md')}
                  header={
                    UnderBreakpoint(screens, 'md')
                      ? t('Filters')
                      : selectProps.value.length > 0
                      ? t('Filters applied')
                      : ''
                  }
                  key="1"
                >
                  <Col className="side-filter" span={24}>
                    {selectProps.value.length > 0 && (
                      <div className="block-filter">
                        <Select {...selectProps} />
                        <Button
                          className="block-filter-clear-button"
                          onClick={clearFilters}
                        >
                          {t('Clear filters')}
                        </Button>
                      </div>
                    )}

                    {/* NOMBRE */}                    
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Name')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showProducts ? (
                            <Button
                              type="text"
                              onClick={() => setShowProducts(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowProducts(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showProducts && (
                          <Col xs={24}>
                            <Space direction="vertical">                                                           
                              {Children.toArray(                                
                                products.map(prod => (
                                  <Checkbox
                                    value={prod}
                                    name={prod}
                                    checked={
                                      productsFilter == prod
                                    }
                                    onChange={e => {
                                      if (productsFilter == prod){
                                        setProductsFilter('');
                                        setProductsFilterName('');
                                      }else{
                                        setProductsFilter(prod);
                                        setProductsFilterName(prod);
                                      }
                                    }}
                                  >
                                    {prod}                             
                                  </Checkbox>
                                ))
                              )}
                            </Space>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {/* DISPONIBILIDAD */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Availability')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showStock ? (
                            <Button
                              type="text"
                              onClick={() => setShowStock(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowStock(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showStock && (
                          <Col xs={24}>
                            <Space direction="vertical">
                              <Checkbox
                                    value='only-in-stock'
                                    name='only-in-stock'
                                    checked={
                                      stockFilter
                                    }                                    
                                    onChange={e => {
                                      if (stockFilter){
                                        setStockFilter(false);
                                        setStockFilterName('');
                                      }else{
                                        setStockFilter(true);
                                        setStockFilterName('In stock');
                                      }
                                    }}                                    
                                  >                                    
                                    {i18n.language === 'es'
                                      ? 'Mostrar unicamente productos en stock'
                                      : "Show only products in stock"}
                                  </Checkbox>
                            </Space>
                          </Col>
                        )}                        
                      </Row>
                    </div>

                    {/* TONALIDAD */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Tonality')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showTonalities ? (
                            <Button
                              type="text"
                              onClick={() => setShowTonalities(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowTonalities(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showTonalities && (
                          <Col xs={24}>
                            <Space direction="vertical">
                              {Children.toArray(
                                tonalities.map(ton => (
                                  <Checkbox
                                    value={ton.id}
                                    name={ton.name_es}
                                    checked={
                                      tonalitiesFilter == ton.id.toString()
                                    }
                                    onChange={e => {
                                      if (tonalitiesFilter == ton.id.toString()){
                                        setTonalitiesFilter('');
                                        setTonalitiesFilterName('');
                                      }else{
                                        setTonalitiesFilter(ton.id.toString());
                                        setTonalitiesFilterName(i18n.language === 'es' ? ton.name_es : ton.name_en);
                                      }
                                    }
                                    }
                                  >
                                    {i18n.language === 'es'
                                      ? ton.name_es
                                      : ton.name_en}
                                  </Checkbox>
                                ))
                              )}
                            </Space>
                          </Col>
                        )}
                      </Row>
                    </div>
                    
                    {/* Weights */}
                    {typeSearch === 'H' && (
                      <div className="block-filter">
                        <Row className="justify-between">
                          <Col className="banner" xs={22} lg={23}>
                            <Text>{t('Weight')}</Text>
                          </Col>
                          <Col className="arrow-container" xs={2} lg={1}>
                            {showWeights ? (
                              <Button
                                type="text"
                                onClick={() => setShowWeights(false)}
                                className="btnContainer"
                                icon={<UpOutlined />}
                              />
                            ) : (
                              <Button
                                type="text"
                                onClick={() => setShowWeights(true)}
                                className="btnContainer"
                                icon={<DownOutlined />}
                              />
                            )}
                          </Col>
                          {showWeights && (
                            <Col xs={24}>
                              <Radio.Group
                                onChange={e => setWeightFilter(e.target.value)}
                                value={weightFilter}
                              >
                                <Space direction="vertical">
                                  {Children.toArray(
                                    weights.map(we => (
                                      <Radio value={we.id}>
                                        {i18n.language === 'es'
                                          ? we.name_es
                                          : we.name_en}
                                      </Radio>
                                    ))
                                  )}
                                </Space>
                              </Radio.Group>
                            </Col>
                          )}
                        </Row>
                      </div>
                    )}                   

                    {/* COMPOSITION */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Composition')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showAttributes ? (
                            <Button
                              type="text"
                              onClick={() => setShowAttributes(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowAttributes(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showAttributes && (
                          <Col xs={24}>
                            <Space direction="vertical">
                              {Children.toArray(
                                attributes.map(cat => (
                                  <Checkbox
                                    value={cat.id}
                                    name={cat.name}
                                    checked={
                                      atributesFilter.indexOf(cat.id) !== -1
                                    }
                                    onChange={e => handleAttributes(e)}
                                  >
                                    {i18n.language === 'es'
                                      ? cat.name_es
                                      : cat.name_en}
                                  </Checkbox>
                                ))
                              )}
                            </Space>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {/* METRIC NUMBER */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Metric number (NM)')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showMetricNumbers ? (
                            <Button
                              type="text"
                              onClick={() => setShowMetricNumbers(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowMetricNumbers(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showMetricNumbers && (
                          <Col xs={24}>
                            <Radio.Group
                              onChange={e =>
                                setMetricNumbersFilter(e.target.value)
                              }
                              value={metricNumbersFilter}
                            >
                              <Space direction="vertical">
                                {Children.toArray(
                                  metricNumbers.map(met => (
                                    <Radio value={met.id}>
                                      {i18n.language === 'es'
                                        ? met.name
                                        : met.name}
                                    </Radio>
                                  ))
                                )}
                              </Space>
                            </Radio.Group>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {/* SUGGESTED NEEDLE */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          {typeSearch === 'I' && <Text>{t('Gaugge')}</Text>}
                          {typeSearch === 'H' && (
                            <Text>{t('Suggested needle')}</Text>
                          )}
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showSuggestedNeedles ? (
                            <Button
                              type="text"
                              onClick={() => setShowSuggestedNeedles(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowSuggestedNeedles(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showSuggestedNeedles && (
                          <Col xs={24}>
                            <Radio.Group
                              onChange={e =>
                                setSuggestedNeedlesFilter(e.target.value)
                              }
                              value={suggestedNeedlesFilter}
                            >
                              <Space direction="vertical">
                                {Children.toArray(
                                  suggestedNeedles.map(sug => (
                                    <>
                                      {sug.category === typeSearch && (
                                        <Radio value={sug.id}>
                                          {i18n.language === 'es'
                                            ? sug.name
                                            : sug.name}
                                        </Radio>
                                      )}
                                    </>
                                  ))
                                )}
                              </Space>
                            </Radio.Group>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {/* COLLECTION */}
                    <div className="block-filter">
                      <Row className="justify-between">
                        <Col className="banner" xs={22} lg={23}>
                          <Text>{t('Subcategory')}</Text>
                        </Col>
                        <Col className="arrow-container" xs={2} lg={1}>
                          {showSubCategory ? (
                            <Button
                              type="text"
                              onClick={() => setShowSubCategory(false)}
                              className="btnContainer"
                              icon={<UpOutlined />}
                            />
                          ) : (
                            <Button
                              type="text"
                              onClick={() => setShowSubCategory(true)}
                              className="btnContainer"
                              icon={<DownOutlined />}
                            />
                          )}
                        </Col>
                        {showSubCategory && (
                          <Col xs={24}>
                            <Radio.Group
                              onChange={e => setSubCategory(e.target.value)}
                              value={subCategory}
                            >
                              <Space direction="vertical">
                                {Children.toArray(
                                  subCategories.map(cat => (
                                    <Radio value={cat.id}>
                                      {i18n.language === 'es'
                                        ? cat.name_es
                                        : cat.name_en}
                                    </Radio>
                                  ))
                                )}
                              </Space>
                            </Radio.Group>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Panel>
              </Collapse>
            </Row>
          </Col>

          <Col md={16} className="gutter-row">
            {!showSearch ? (
              <Row className="left-col-aa">
                {isLoading ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    {Children.toArray(
                      allProducts.map((product, index) => (
                        <>
                          <Col
                            xs={24}
                            md={12}
                            className={index % 2 === 0 && 'border-right'}
                          >
                            <SearchColorDetail
                              product={product}
                              tyPath={tyPath}
                              inStock={stockFilter}
                              tonality={tonalitiesFilter}
                            />
                          </Col>
                          {index % 2 !== 0 && <Border position="middle" />}
                        </>
                      ))
                    )}
                  </>
                )}
              </Row>
            ) : (
              <>
                <Row className="left-col-aa">
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <>
                      {Children.toArray(
                        allProducts.map((product, index) => (
                          <>
                            <Col
                              xs={24}
                              md={12}
                              className={index % 2 === 0 && 'border-right'}
                            >
                              <SearchColorDetail
                                product={product}
                                tyPath={tyPath}
                                inStock={stockFilter}
                                tonality={tonalitiesFilter}
                              />
                            </Col>
                            {index % 2 !== 0 && <Border position="middle" />}
                          </>
                        ))
                      )}
                    </>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
export default SearchPage;
