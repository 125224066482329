// Import core modules
import React, { useState } from 'react';
import { Button, Modal, Row, Col, Typography, Space } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FacebookFilled,
  InstagramOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import { FaVimeoV } from 'react-icons/fa';
import CookieConsent from 'react-cookie-consent';

// Import Components
import ImageModal from './Modals/index';

// Import Images
import amano from '~/Resources/images/amano.png';
import grupoInca from '~/Resources/images/grupo_inca.svg';
import GrupoInca from '~/Resources/images/GrupoInca.svg';
import incalpaca from '~/Resources/images/incalpaca.svg';
import kuna from '~/Resources/images/kuna.svg';
import logoAndean from '~/Resources/images/logo_andean.svg';
import pacomarca from '~/Resources/images/pacomarca.svg';
import whyAlpaca from '~/Resources/images/WhyAlpaca.svg';
import confidence from '~/Resources/images/confidence.svg';
import certification from '~/Resources/images/certification_interwoollabs.jpg';
import huellaCarbono from '~/Resources/images/huella_carbono.png';
import logoOEA from '~/Resources/images/logo_oea_gris.png';
import ModalLogoOEA from '~/Resources/images/ModalLogoOEA.png';
import ModalHuellaCarbono from '~/Resources/images/ModalHuellaCarbono.png';
import ModalConfidence from '~/Resources/images/ModalConfidence.jpg';
import ModalTraceable from '~/Resources/images/traceable_alpaca.png';
import ModalRas from '~/Resources/images/RAS.png';
import ModalRms from '~/Resources/images/RMS.png';
import ModalRws from '~/Resources/images/RWS.png';
import ModalOekoStep from '~/Resources/images/OEKO-TEX-STeP.png';
import ModalOekoC1 from '~/Resources/images/OEKO-TEX-Clase-1.jpg';
import ModalOekoC2 from '~/Resources/images/OEKO-TEX-Clase-2.jpg';

// Utils
import { UnderBreakpoint } from '~/Utils/Functions';

// Import Styles
import './index.scss';

const { Text } = Typography;

function AppFooter() {
  const screens = useBreakpoint();
  const { t } = useTranslation('common');

  const [showTerms, setShowTerms] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [getImageModal, setGetImageModal] = useState('');
  const [typeModal, setTypeModal] = useState('');

  const openModalTerms = () => {
    setTypeModal('terms');
    setShowTerms(true);
  };

  const openModalCookies = () => {
    setTypeModal('cookies');
    setShowTerms(true);
  };

  const openImageModal = newImageModal => {
    setGetImageModal(newImageModal);
    setShowImageModal(true);
  };

  return (
    <>
      <Row
        align="middle"
        justify={UnderBreakpoint(screens, 'lg') ? 'center' : 'space-between'}
        className="AppFooter"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col className="text-container" lg={3} md={24} sm={24} xs={24}>
          <Space
            className="footer-text"
            align="center"
            direction="horizontal"
            wrap
          >
            <Link
              to={{
                pathname: 'https://www.facebook.com/IncaTopsAlpaca/',
              }}
              target="_blank"
            >
              <FacebookFilled className="icons" />
            </Link>
            <Link
              to={{
                pathname: 'https://www.instagram.com/inca_tops/',
              }}
              target="_blank"
            >
              <InstagramOutlined className="icons" />
            </Link>
            <Link
              to={{
                pathname:
                  'https://www.youtube.com/channel/UCvvUhql-NOfKVmYvLnbSJaA',
              }}
              target="_blank"
            >
              <YoutubeFilled className="icons" />
            </Link>
          </Space>
        </Col>
        <Col className="text-container" lg={10} md={24} sm={24} xs={24}>
          <Space
            className="footer-text"
            align="center"
            direction="vertical"
            wrap
          >
            <Space
              className="footer-text"
              align="center"
              direction="horizontal"
              wrap
            >
              <Link
                to={{
                  pathname: 'https://grupoinca.com/',
                }}
                target="_blank"
              >
                <img
                  src={grupoInca}
                  alt="Grupo Inca 1"
                  className="grupo-inca"
                />
              </Link>
              <Link
                to={{
                  pathname: 'https://whyalpaca.com/',
                }}
                target="_blank"
              >
                <img src={whyAlpaca} alt="Why Alpaca" className="why-alpaca" />
              </Link>
              <Link
                to={{
                  pathname: 'http://alpacaisalifestyle.com/',
                }}
                target="_blank"
              >
                <img src={GrupoInca} alt="Grupo Inca" className="gruporInca" />
              </Link>
              <Link
                to={{
                  pathname: 'https://pacomarca.com/es/',
                }}
                target="_blank"
              >
                <img src={pacomarca} alt="Pacomarca" className="pacomarca" />
              </Link>
            </Space>
            <Space
              className="footer-text"
              align="center"
              direction="horizontal"
              wrap
            >
              <Link
                to={{
                  pathname: 'https://www.incalpaca.com/',
                }}
                target="_blank"
              >
                <img src={incalpaca} alt="incalpaca" className="incalpaca" />
              </Link>
              <Link
                to={{
                  pathname: 'https://kunastores.com/peru/',
                }}
                target="_blank"
              >
                <img src={kuna} alt="Kuna" className="kuna" />
              </Link>
              <Link
                to={{
                  pathname: 'https://andean.com.pe/',
                }}
                target="_blank"
              >
                <img src={logoAndean} alt="Andean" className="andean" />
              </Link>
              <Link
                to={{
                  pathname: 'http://amanoyarns.com/',
                }}
                target="_blank"
              >
                <img src={amano} alt="Amano" className="amano" />
              </Link>
            </Space>
          </Space>
        </Col>
        <Col className="text-certifications" lg={4} md={24} sm={24} xs={24}>
          <Space
            className="footer-text"
            align="center"
            direction="horizontal"
            wrap
          >
            <Text className="details"> {t('Certifications')} </Text>
          </Space>
        </Col>
        <Col className="text-container" lg={7} md={24} sm={24} xs={24}>
          <Space
            className="footer-text"
            align="center"
            direction="horizontal"
            wrap
          >
            <Button
              className="image-button"
              onClick={() => openImageModal(ModalTraceable)}
            >
              <img
                src={ModalTraceable}
                alt="TRACEABLE ALPACA BY SGS"
                className="traceable"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(certification)}
            >
              <img
                src={certification}
                alt="certification"
                className="certification"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalLogoOEA)}
            >
              <img src={logoOEA} alt="logoOEA" className="logoOEA" />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalRas)}
            >
              <img
                src={ModalRas}
                alt="RAS - RESPONSIBLE ALPACA STANDARD"
                className="ras"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalRms)}
            >
              <img
                src={ModalRms}
                alt="RWS - RESPONSIBLE WOOL STANDARD"
                className="rms"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalRws)}
            >
              <img src={ModalRws} alt="" className="rws" />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalOekoC1)}
            >
              <img
                src={ModalOekoC1}
                alt="OEKO-TEX - STANDAR 100 Class 1"
                className="oeko-c1"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalOekoC2)}
            >
              <img
                src={ModalOekoC2}
                alt="OEKO-TEX - STANDAR 100 Class 2"
                className="oeko-c2"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalOekoStep)}
            >
              <img
                src={ModalOekoStep}
                alt="OEKO-TEX - STeP"
                className="oeko-step"
              />
            </Button>

            <Button
              className="image-button"
              onClick={() => openImageModal(ModalHuellaCarbono)}
            >
              <img
                src={huellaCarbono}
                alt="huellaCarbono"
                className="huellaCarbono"
              />
            </Button>
          </Space>
        </Col>
        <Col className="text-container" md={24} xs={24}>          
          <p className="footer-contact-us">
            <a href="mailto:stockservice@incatops.com">
              Contact us: stockservice@incatops.com
            </a>            
          </p>
          <Space
            className="footer-text vertical-bar"
            align="center"
            direction="horizontal"
          >
            <Link
              className="terms-conditions"
              to="/privacy-policy"
              target="_blank"
            >
              {t('Privacy policy')}
            </Link>
            <Text>|</Text>
            <Link
              className="terms-conditions"
              to="/cookies-policy"
              target="_blank"
            >
              {t('Cookies Policy')}
            </Link>
          </Space>
        </Col>
        <Modal
          visible={showImageModal}
          onCancel={() => setShowImageModal(false)}
          footer={null}
          width={700}
          bodyStyle={{ backgroundColor: 'white' }}
        >
          <ImageModal image={getImageModal} />
        </Modal>
        <CookieConsent
          location="bottom"
          buttonText={t('I agree')}
          buttonClasses="cookie-consent-button"
          contentClasses="cookie-consent-content"
          containerClasses="cookie-consent-container"
        >
          {t(
            'This website uses cookies. We use cookies to personalize content and ads, provide social media features, and to analyze our traffic. Read more'
          )}
          <Link className="button-cookies" to="/cookies-policy" target="_blank">
            {t('here.')}
          </Link>
        </CookieConsent>
      </Row>
    </>
  );
}

AppFooter.propTypes = {};

export default AppFooter;
