// Import Styles
import './index.scss';

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Typography,
} from 'antd';
// Context
import { CART_ACTIONS, CartContext } from '~/Contexts/CartProvider';
import {
  DeleteFilled,
  MailOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
// Import Utils
import { HandleErroMessage, UnderBreakpoint } from '~/Utils/Functions';
// Import core modules
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueries, useQuery } from 'react-query';

import Api from '~/Utils/Api';
// Import Components
import Border from '~/Components/Border';
import ColorCircle from '~/Components/ColorCircle';
import Cone from '~/Resources/images/ic_cone.svg';
import DonutBall from '~/Resources/images/ic_donut_ball.svg';
import ForgotPassword from '~/Components/ForgotPassword';
import { Link } from 'react-router-dom';
import Login from '~/Components/Login';
import PeanutBall from '~/Resources/images/ic_peanut_ball.svg';
import Register from '~/Components/Register';
import ResetPassword from '~/Components/ResetPassword';
import TermsConditions from '../../Components/TermsConditions';
import TwistedHank from '~/Resources/images/ic_twisted_hank.svg';
import { UserContext } from '~/Contexts/UserProvider';
import jwt from 'jsonwebtoken';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

function QuotationPage() {
  const [state, dispatch] = useContext(CartContext);
  const [stateUser] = useContext(UserContext);
  const [succes, setSucces] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [terms, setTerms] = useState(true);
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { t } = useTranslation('common');
  const [showTerms, setShowTerms] = useState(false);
  const [show] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForget, setShowForget] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showErrorQuotation, setShowErrorQuotation] = useState(false);
  const [resetToken] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const [quantityChange, setQuantityChange] = useState();
  const [colorChange, setColorChange] = useState(0);
  const [colorID, setColorID] = useState(0);
  const [validateOrders, setValidateOrders] = useState([]);
  const [allProductsValidate, setAllProductsValidate] = useState(true);
  const [firstReview, setFirstReview] = useState(true);

  const { error: errorColor, data: colorData } = {};

  const getImgbyText = value => {
    switch (value) {
      case 'Peanut Ball':
        return PeanutBall;
      case 'Donut Ball':
        return DonutBall;
      case 'Twisted Hank':
        return TwistedHank;
      case 'Cone':
        return Cone;
    }
  };

  const getPresentationWeightDisplay = value => {
    switch (value) {
      case '25 g':
        return '25 g';
      case '50 g':
        return '50 g';
      case '10 g':
        return '100 g';
      case '20 g':
        return '200 g';
      case '1 kg':
        return '1 kg';
    }
  };

  const results = useQueries(
    state.cartItems.map(product => {
      return {
        queryKey: ['productDB', product.colorID],
        queryFn: async key => {
          const res = await Api.get(
            `/api/products/colours/${key.queryKey[1]}/`
          );
          return res;
        },
      };
    })
  );

  useEffect(() => {
    if (jwt.decode(stateUser.userToken)) {
      setCurrentToken(jwt.decode(stateUser.userToken).data);
    }
  }, [stateUser]);

  useEffect(() => {
    if (!firstReview) {
      if (colorData) {
        setColorChange(colorData.data);
      }
      if (errorColor) {
        HandleErroMessage(errorColor);
      }
    }
  }, [colorData, errorColor]);

  useEffect(() => {
    results.forEach((productDB, i) => {
      const { data, isLoading: isLoadingData } = productDB;
      if (!isLoadingData) {
        if (firstReview) {
          setColorID(data.data.id);
          setColorChange(data.data);
          setQuantityChange(state.cartItems[i].quantity);
        }
      }
    });
  }, [results, firstReview]);

  useEffect(() => {
    if (state.cartItems.length > 0) {
      const productsValidators = state.cartItems.map(product => {
        return {
          product,
          validate: true,
        };
      });
      setValidateOrders(productsValidators);
    }
  }, [state.cartItems.length]);

  useEffect(() => {
    if (validateOrders.length > 0) {
      if (
        colorChange.stock_set &&
        colorChange.restock_set[0] &&
        (quantityChange > colorChange.stock_set[0].amount ||
          colorChange.stock_set.length === 0)
      ) {
        const newValues = validateOrders.map(order => {
          if (order.product.colorID === colorID) {
            return {
              ...order,
              validate: false,
            };
          }
          return order;
        });
        setValidateOrders(newValues);
      } else if (quantityChange > colorChange.stock_set) {
        const newValues = validateOrders.map(order => {
          if (order.product.colorID === colorID) {
            return {
              ...order,
              validate: false,
            };
          }
          return order;
        });
        setValidateOrders(newValues);
      } else if (
        colorChange.stock_set !== undefined &&
        quantityChange > colorChange.stock_set[0].amount
      ) {
        const newValues = validateOrders.map(order => {
          if (order.product.colorID === colorID) {
            return {
              ...order,
              validate: false,
            };
          }
          return order;
        });
        setValidateOrders(newValues);
      } else {
        const newValues = validateOrders.map(order => {
          if (order.product.colorID === colorID) {
            return {
              ...order,
              validate: true,
            };
          }
          return order;
        });
        setValidateOrders(newValues);
      }
    }
  }, [colorChange, quantityChange]);

  useEffect(() => {
    const validateAll = validateOrders.filter(order => !order.validate);
    setAllProductsValidate(!(validateAll.length === 0));
  }, [validateOrders]);

  const openModalTerms = () => {
    setShowTerms(true);
  };

  const deleteItemInCart = product => {
    dispatch({
      type: CART_ACTIONS.DELETE_ITEM,
      payload: product,
    });
    setFirstReview(false);
  };
  const updateItemInCart = product => {
    setColorID(product.colorID);
    dispatch({
      type: CART_ACTIONS.UPATE_ITEM,
      payload: product,
    });
    setFirstReview(false);
  };
  const clearItemsInCart = () => {
    dispatch({
      type: CART_ACTIONS.CLEAR,
    });
    setFirstReview(false);
  };

  const mutation = useMutation(
    data =>
      Api.post('/api/quotations/quotations/', data, {
        headers: {
          Authorization: `Token ${currentToken}`,
        },
      }),
    {
      onError: error => {
        const errorMessagePost = error.response.data.non_field_errors[0];
        const otherError = t('An error has occurred, please try again');
        errorMessagePost !== ''
          ? setErrorMessage(errorMessagePost)
          : setErrorMessage(otherError);
        setSucces(false);
        setShowErrorQuotation(true);
      },
      onSuccess: () => {
        clearItemsInCart();
        setSucces(true);
      },
    }
  );

  const { isLoading, error, data: salesmenData } = useQuery(
    'fetchSalesmen',
    () => Api.get('/api/users/sellers/')
  );

  const onChange = e => {
    setTerms(!e.target.checked);
  };

  const onSubmitForm = values => {
    const itemSet = state.cartItems.map(color => {
      const presentation = color.presentation.charAt(0);
      const weight = color.presentationWeigthValue.replace(/\D/g, '');
      return {
        colour: color.colorID,
        amount: color.quantity,
        presentation: presentation,
        presentation_weight: weight,
      };
    });
    const newValues = { ...values };
    mutation.mutate({ seller: newValues.salesman, item_set: itemSet });
  };

  return (
    <div className="QuotationPage">
      <Row justify="center">
        <Col className="title-container optima" span={24}>
          <Title className="f500 title" level={4}>
            {t('Request')}
          </Title>
        </Col>
        <Col span={24}>
          <Border position="default" />
        </Col>
        <Layout className="layout-ctn">
          <Col span={24} order={UnderBreakpoint(screens, 'md') ? 1 : 0}>
            {state.cartItems.length ? (
              state.cartItems.map((product, i) => (
                <>
                  <React.Fragment>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row
                          align="middle"
                          justify={
                            UnderBreakpoint(screens, 'md')
                              ? 'space-between'
                              : 'space-around'
                          }
                        >
                          <Col
                            span={UnderBreakpoint(screens, 'md') ? 24 : 2}
                            offset={UnderBreakpoint(screens, 'md') ? 0 : 1}
                          >
                            <Text className="presentation-text">
                              {t('Product')}
                            </Text>
                          </Col>
                          {!UnderBreakpoint(screens, 'md') && (
                            <Col
                              className="center-image"
                              span={UnderBreakpoint(screens, 'md') ? 'none' : 4}
                            >
                              <Text className="presentation-text">
                                {t('Colour')}
                              </Text>
                            </Col>
                          )}
                          <Col
                            className="presentation-item"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 4}
                          >
                            <Text className="presentation-text">
                              {t('Presentation')}
                            </Text>
                          </Col>
                          <Col
                            className="input-number-wrapper quantity-container"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 2}
                          >
                            <Text className="presentation-text">
                              {t('Format')}
                            </Text>
                          </Col>
                          <Col
                            className="input-number-wrapper quantity-container"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 4}
                          >
                            <Text className="presentation-text">
                              {t('Kg Ordered')}
                            </Text>
                          </Col>
                          <Col
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 3}
                            className=" quantity-container"
                          ></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Border position="default" />
                  </React.Fragment>
                  <React.Fragment key={`${product.name}-${product.colorImage}`}>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row
                          align="middle"
                          justify={
                            UnderBreakpoint(screens, 'md')
                              ? 'space-between'
                              : 'space-around'
                          }
                          className="product-container"
                        >
                          <Col
                            span={UnderBreakpoint(screens, 'md') ? 24 : 2}
                            offset={UnderBreakpoint(screens, 'md') ? 0 : 1}
                          >
                            <Title className="product-title f500" level={3}>
                              {product.name}
                            </Title>
                          </Col>
                          {!UnderBreakpoint(screens, 'md') && (
                            <Col
                              className="center-image"
                              span={UnderBreakpoint(screens, 'md') ? 'none' : 2}
                            >
                              <ColorCircle
                                big
                                img={product.colorImage}
                                code={product.colorCode}
                              />
                            </Col>
                          )}
                          <Col
                            className="presentation-item"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 4}
                          >
                            <img
                              src={getImgbyText(product.presentation)}
                              alt=""
                              className="secondary"
                            />
                            <Text className="light-text f500">
                              {t(product.presentation)}
                            </Text>
                          </Col>
                          <Col
                            className="input-number-wrapper quantity-container"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 2}
                          >
                            <Text className="presentation-text">
                              {getPresentationWeightDisplay(
                                product.presentationWeigthValue
                              )}
                            </Text>
                          </Col>
                          <Col
                            className="input-number-wrapper quantity-container"
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 4}
                          >
                            <InputNumber
                              defaultValue={product.quantity}
                              min={product.presentation === 'Cone' ? 1 : 6}
                              value={product.quantity}
                              formatter={value => `${value} kg`}
                              parser={value => value.replace('kg', '')}
                              onChange={val => {
                                setQuantityChange(val);
                                const newProduct = { ...product };
                                newProduct.quantity = val;
                                updateItemInCart(newProduct);
                              }}
                            />
                          </Col>
                          <Col
                            span={UnderBreakpoint(screens, 'md') ? 'none' : 3}
                            className=" quantity-container"
                          >
                            <Button
                              type="text"
                              size="large"
                              icon={<DeleteFilled className="trash-icon" />}
                              onClick={() => deleteItemInCart(product)}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {validateOrders.length > 0 &&
                      !validateOrders[i].validate &&
                      validateOrders[i].product.colorID === product.colorID ? (
                        <Col className="stock-alert" span={24}>
                          <Row
                            className="stock-alert"
                            gutter={4}
                            justify="end"
                            align="middle"
                          >
                            <Alert
                              message={t(
                                'The order is greater than the current stock'
                              )}
                              type="warning"
                            />
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                    <Border position="default" />
                  </React.Fragment>
                </>
              ))
            ) : succes ? (
              <div className="empty-container">
                <MailOutlined className="mail-icon" />
                <Text className="empty-text-bottom f500" level={4}>
                  {t('Your enquiry has been sent.')}
                </Text>
                <Text className="empty-text f500" level={4}>
                  {t('It will be processed in the next 24/48 hours.')}
                </Text>
                <Text className="empty-text f500" level={4}>
                  {t('Please, check your Spam folder.')}
                </Text>
                <Link to="/">
                  <Button type="primary">{t('Back to home')}</Button>
                </Link>
              </div>
            ) : (
              <div className="empty-container">
                <ShoppingOutlined className="cart-icon" />
                <Text className="empty-text f500" level={4}>
                  {t('Your cart is currently empty')}
                </Text>
                <Link to="/">
                  <Button type="primary">{t('Back to home')}</Button>
                </Link>
              </div>
            )}
          </Col>
        </Layout>

        {state.cartItems.length ? (
          <>
            <Col xs={24}>
              <Text className="empty-text f500">
                {t(
                  '* Referential weight subject to confirmation at the time of the shipment.'
                )}
              </Text>
            </Col>
            <Col span={24} order={UnderBreakpoint(screens, 'md') ? 0 : 1}>
              <Row align="middle">
                {!UnderBreakpoint(screens, 'md') && (
                  <Col className="title-container optima" span={24}>
                    <Title className="f500 title" level={4}>
                      {t('Information')}
                    </Title>
                  </Col>
                )}
                <Col span={24}>
                  <Form
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    layout="inline"
                    validateTrigger="onSubmit"
                    size="large"
                    onFinish={onSubmitForm}
                  >
                    <Row justify="start" className="form-container">
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name="salesman"
                          rules={[
                            {
                              required: true,
                              message: t('This field is required'),
                            },
                          ]}
                          extra={`(*) ${t('This field is required')}`}
                        >
                          <Select
                            placeholder={t('Sales Executive (*)')}
                            loading={isLoading}
                          >
                            {salesmenData &&
                              salesmenData.data.map(salesman => (
                                <Select.Option
                                  style={{ height: 36 }}
                                  value={salesman.user.id}
                                  key={salesman.user.id}
                                >
                                  <Text
                                    className="custom-select-option"
                                    level={4}
                                  >
                                    {salesman.get_full_name}
                                  </Text>
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={24}>
                        <Form.Item
                          name="terms"
                          rules={[
                            {
                              required: terms,
                              message: t(
                                'You must accept the terms and conditions'
                              ),
                            },
                          ]}
                        >
                          <Checkbox onChange={onChange}>
                            <Text className="empty-text f500">
                              {t('Accept ')}
                            </Text>
                            <button
                              type="button"
                              className="terms"
                              onClick={openModalTerms}
                            >
                              {t('Terms and conditions')}
                            </button>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col
              className="send-button"
              span={24}
              order={UnderBreakpoint(screens, 'md') ? 2 : 2}
            >
              <Button
                type="primary"
                disabled={allProductsValidate || mutation.isLoading}
                onClick={() => {
                  if (stateUser) {
                    form.submit();
                  } else {
                    setShowLog(!show);
                  }
                }}
              >
                {t('Send Request')}
              </Button>
            </Col>
          </>
        ) : (
          <Border position="default" />
        )}
        <Modal
          title={t('Privacy policy')}
          visible={showTerms}
          onCancel={() => setShowTerms(false)}
          footer={null}
          width={700}
        >
          <TermsConditions typeModal="terms" />
        </Modal>
        <Modal
          visible={showErrorQuotation}
          onCancel={() => setShowErrorQuotation(false)}
          footer={null}
          width={700}
        >
          <div className="quotation-error-ctn">
            <Title className="quotation-error-title" level={4}>
              {t('WARNING')}
            </Title>
            <Text className="quotation-error-text">{t(errorMessage)}</Text>
          </div>
        </Modal>
        <Modal
          visible={showLog}
          onCancel={() => setShowLog(false)}
          footer={null}
          width={700}
        >
          <Login
            showPopover={v => setShowLog(v)}
            setShowForget={setShowForget}
            setShowRegister={setShowRegister}
          />
        </Modal>
        <Modal
          visible={showRegister}
          onCancel={() => setShowRegister(false)}
          footer={null}
          width={800}
        >
          <Register showPopover={v => setShowRegister(v)} />
        </Modal>
        <Modal
          visible={showForget}
          onCancel={() => setShowForget(false)}
          footer={null}
          width={700}
        >
          <ForgotPassword showPopover={v => setShowForget(v)} />
        </Modal>
        <Modal
          visible={showReset}
          onCancel={() => setShowReset(false)}
          footer={null}
          width={700}
        >
          <ResetPassword
            showPopover={v => setShowReset(v)}
            resetToken={resetToken}
          />
        </Modal>
      </Row>
    </div>
  );
}
QuotationPage.propTypes = {};
export default QuotationPage;
